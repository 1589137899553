import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "components/Layout/Container";
import { AppContext } from "components/App/AppContext";
import { getProgramContractors, useProgramContractorSearchParams } from "./getProgramContractors";
import { Button } from "components/Button";
import { Alert, Card, Spinner } from "react-bootstrap";
import { isEmpty, isNil, uniqBy, capitalize, set } from "lodash";
import "./ContractorMapPage.scss";
import { ContractorsResponse, ContractorsResponseItem } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { ContractorData } from "components/utils/contacts";
import cn from "classnames";
import { Heading } from "components/Heading";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageComponentsList, PageContext } from "..";
import { ErrorSummaryInterface } from "types/ErrorSummary";
import { ErrorSummary } from "components/ErrorSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaQuery from "components/utils/useMediaQuery";
import { Icon } from "components/Icon";
import CurrentLocationMarker from "./assets/current-location-marker.png";
import CustomMarkerSelected from "./assets/custom-marker-selected.png";
import { SubmitButton } from "components/Button/SubmitButton";
import { ContractorDetailsOffcanvas } from "./ContractorDetailsOffcanvas";
import {
    SingleFilterItem,
    SingleFilterMultiSelectItem,
    SingleFilterSelectItem,
    getFilters,
    getSearchAttr,
    getZipFilterActive,
} from "./FilterItems";
import { Filter, LoadingTypes, MarkerProps, UserCoordinates } from "./types";
import { filterTitles, gatherMarkers, getCenter, hasValidCoordinates, openInfoWindow } from "./utils";
import { Tag } from "components/Tag";
import { ContractorMap } from "./ContractorMap";
import { ContractorCard } from "./ContractorCard";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import CustomMarker from "./assets/custom-marker.png";
import { isEmail, isPhoneNumber, isPostalCode } from "components/utils/validation";
import { TypographyFontSize } from "types/TypographyFontSize";

const LIST_CONTAINER_ID = "contractor-list-container";
const MAX_CONTRACTORS = 600;

export const ContractorMapPage = () => {
    const {
        programNumber = "",
        settings: { fontSize },
    } = useContext(AppContext);
    const { topRows, bottomRows } = usePageTopAndBottomRows();
    const page = useContext(PageContext);
    const [contractorSearchParams, isLoadingParams] = useProgramContractorSearchParams(programNumber);
    const [selectedContractor, setSelectedContractor] = useState<ContractorsResponseItem | null>(null);
    const [contractorData, setContractorData] = useState<ContractorsResponse>();
    const [isLoading, setIsLoading] = useState<LoadingTypes>(LoadingTypes.Loading);
    const [mapInitialized, setMapInitialized] = useState<boolean>(false);
    const initialDataRequested = useRef<boolean>(false);
    const pageSize = 150;
    const [pageNumber, setPageNumber] = useState(1);
    const [showList, setShowList] = useState(true);
    const [showMap, setShowMap] = useState(false);
    const [deviceEstablished, setDeviceEstablished] = useState(false);
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);
    const userLocationRequested = useRef(false);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const contractorsWithMarkersRef = useRef<MarkerProps[]>([]);
    const mapRef = useRef<google.maps.Map>(null) as React.MutableRefObject<google.maps.Map>;
    const openedInfoWindowRef = useRef(null);
    const userPressedLocationPrompt = useRef(false);
    const mapInitialisationResolver = useRef<(value: boolean) => void>();
    const firstMapLoadFinished = useRef(false);
    const usermarker = useRef<google.maps.Marker>();
    const [contractorOnSlideout, setContractorOnSlideout] = useState<ContractorsResponseItem | null>(null);
    const [loadMoreEnabled, setLoadMoreEnabled] = useState<boolean>();
    const [showAllTags, setShowAllTags] = useState<boolean>();
    const [filterErrors, setFilterErrors] = useState<{ [key: string]: string }>();
    const clustererRef = useRef<MarkerClusterer>(null);
    const latestSearchAttrRef = useRef("");
    const [showLimitAlert, setShowLimitAlert] = useState(false);

    const mapInitialisedPromise = useMemo(
        () =>
            new Promise((resolve) => {
                mapInitialisationResolver.current = resolve;
            }),
        []
    );

    const userCoordinatesRef = useRef<UserCoordinates | null>(null);

    const [errorSummary, setErrorSummary] = useState<ErrorSummaryInterface>();

    const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
    const [appliedFilters, setAppliedFilters] = useState<Filter[]>([]);

    const setUpMarkers = useCallback(
        (tempMarkers: MarkerProps[]) => {
            contractorsWithMarkersRef.current?.forEach((m: MarkerProps) => {
                m.marker?.setMap(null);
                m.marker = null;
            });
            contractorsWithMarkersRef.current = [];
            if (!!mapRef.current && !!clustererRef.current) {
                contractorsWithMarkersRef.current = tempMarkers.map((m: MarkerProps) => {
                    if (hasValidCoordinates(m)) {
                        const marker = new google.maps.Marker({
                            position: new google.maps.LatLng(m.lat as number, m.lng),
                            title: m.contractor?.company,
                            icon: m.key === "current-location" ? CurrentLocationMarker : CustomMarker,
                        });
                        marker.setMap(mapRef.current);

                        const openMobilePopup = (marker: google.maps.Marker) => {
                            contractorsWithMarkersRef.current?.forEach((m: MarkerProps) => {
                                m.marker?.setIcon(CustomMarker);
                            });
                            marker.setIcon(CustomMarkerSelected);
                            setSelectedContractor(m.contractor as ContractorsResponseItem);
                            mapRef.current?.setCenter({ lat: m.lat as number, lng: m.lng as number });
                        };

                        marker.addListener("click", () => {
                            isMobile
                                ? openMobilePopup(marker)
                                : openInfoWindow(
                                      marker,
                                      m.contractor as ContractorsResponseItem,
                                      mapRef.current as google.maps.Map,
                                      openedInfoWindowRef,
                                      contractorsWithMarkersRef.current
                                  );
                        });

                        return { ...m, marker };
                    } else {
                        return m;
                    }
                });

                clustererRef.current.clearMarkers();
                clustererRef.current.addMarkers(contractorsWithMarkersRef.current.filter((m) => !isNil(m.marker)).map((m) => m.marker!));

                if (!selectedContractor && !userCoordinatesRef.current) {
                    mapRef.current.setCenter(getCenter(tempMarkers, userCoordinatesRef.current));
                }
            }
        },
        [contractorsWithMarkersRef, selectedContractor, setSelectedContractor, isMobile]
    );

    const loadContractors = useCallback(
        (page: number, searchAttr: string, isNewFilter: boolean, loading: LoadingTypes) => {
            if (searchAttr) {
                setAppliedFilterCount(searchAttr?.split("|").length);
            } else {
                setAppliedFilterCount(0);
            }
            setIsLoading(loading);
            setErrorSummary(undefined);
            latestSearchAttrRef.current = searchAttr;
            getProgramContractors({ programNumber, pageNumber: page, pageSize, searchAttr, userCoordinates: userCoordinatesRef.current })
                .then((data) => {
                    if (latestSearchAttrRef.current !== searchAttr) {
                        return;
                    }
                    data.remainingRecords > 0 ? setLoadMoreEnabled(true) : setLoadMoreEnabled(false);
                    if (contractorData?.items && !isNewFilter) {
                        data.items = uniqBy([...contractorData?.items, ...data.items], "contactNumber");
                    }
                    setShowLimitAlert(data.items.length >= MAX_CONTRACTORS);
                    const tempMarkers = gatherMarkers(data);
                    setContractorData(data);
                    setIsLoading(LoadingTypes.NotLoading);

                    if (!firstMapLoadFinished.current) {
                        mapInitialisedPromise.then(() => {
                            setUpMarkers(tempMarkers);
                        });
                    } else {
                        setUpMarkers(tempMarkers);
                    }
                })
                .catch((error) => {
                    setIsLoading(LoadingTypes.NotLoading);
                    setErrorSummary(error);
                });
        },
        [contractorData?.items, mapInitialisedPromise, programNumber, setUpMarkers]
    );

    const setupUserLoaction = useCallback(() => {
        const setup = () => {
            if (!usermarker.current && userCoordinatesRef.current?.latitude && userCoordinatesRef.current?.longitude && mapRef.current) {
                usermarker.current = new google.maps.Marker({
                    position: new google.maps.LatLng(userCoordinatesRef.current.latitude, userCoordinatesRef.current.longitude),
                    title: "My current location",
                    icon: CurrentLocationMarker,
                });
                usermarker?.current?.setMap(mapRef.current);
                mapRef.current.setCenter({
                    lat: userCoordinatesRef.current?.latitude,
                    lng: userCoordinatesRef.current?.longitude,
                });
            }
        };
        if (!firstMapLoadFinished.current) {
            mapInitialisedPromise.then(() => {
                setup();
            });
        } else {
            setup();
        }
    }, [mapInitialisedPromise]);

    useEffect(() => {
        if (!deviceEstablished) {
            if (!isMobile) {
                setShowMap(true);
                setMapInitialized(true);
            }
            setDeviceEstablished(true);
        }
    }, [deviceEstablished, isMobile]);

    useEffect(() => {
        if (!userLocationRequested.current) {
            userLocationRequested.current = true;
            const successCallback = (position: { coords: UserCoordinates }) => {
                const { latitude, longitude } = position.coords;
                userPressedLocationPrompt.current = true;
                const coords = {
                    latitude,
                    longitude,
                };
                userCoordinatesRef.current = coords;
                loadContractors(1, "", true, LoadingTypes.Loading);
                setupUserLoaction();
            };

            const errorCallback = (error: any) => {
                userPressedLocationPrompt.current = true;
            };

            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

            navigator.permissions.query({ name: "geolocation" }).then((result) => {
                if ((result.state === "prompt" || result.state === "denied") && !initialDataRequested.current) {
                    initialDataRequested.current = true;
                    loadContractors(pageNumber, "", false, LoadingTypes.Loading);
                }
            });
        }
    }, [initialDataRequested, loadContractors, pageNumber, setupUserLoaction, userLocationRequested]);

    const [hideFilters, setHideFilters] = useState(true);

    const selectMarkerMobile = (c: ContractorsResponseItem) => {
        if (c.lat && c.lng) {
            selectMarker(c.contactNumber);
            setSelectedContractor(c);
            mapRef.current?.setCenter({ lat: c.lat, lng: c.lng });
            mapRef.current?.setZoom(15);
        }
    };

    const showOnMapMobile = (c: ContractorsResponseItem) => {
        if (!mapInitialized) {
            setMapInitialized(true);
        }
        setShowMap(true);
        if (!firstMapLoadFinished.current) {
            mapInitialisedPromise.then(() => {
                selectMarkerMobile(c);
            });
        } else {
            selectMarkerMobile(c);
        }
    };
    const selectMarker = (contactNumber: string) => {
        const marker = contractorsWithMarkersRef.current?.find((m) => m?.contractor?.contactNumber === contactNumber)?.marker;
        marker?.setIcon(CustomMarkerSelected);
    };

    const zipFilterActive = useMemo(() => getZipFilterActive(selectedFilters), [selectedFilters]);

    const onClearFilter = () => {
        setShowAllTags(false);
        setSelectedFilters([]);
        setAppliedFilters([]);
        loadContractors(1, "", true, LoadingTypes.Loading);
        setHideFilters(true);
        setFilterErrors({});
    };

    const onValidateFilters = (newFilter: Filter[]) => {
        const errors = {};
        const phone = newFilter.find((f) => f.key === "phone");
        const email = newFilter.find((f) => f.key === "email");
        const zip = newFilter.find((f) => f.key === "zip");

        if (!isEmpty(phone?.value) && !isPhoneNumber(phone?.value as string)) {
            set(errors, "phone", "Enter phone number in the correct format, like (000) 000-000 or 000-000-0000");
        }

        if (!isEmpty(email?.value) && !isEmail(email?.value as string)) {
            set(errors, "email", "Enter an email address in the correct format, like name@example.com");
        }

        if (!isEmpty(zip?.value) && !isPostalCode(zip?.value as string)) {
            set(errors, "zip", "Enter a postal code in the correct format, like 00000, 00000-0000, or A0A 0A0");
        }
        setFilterErrors(errors);
        return errors;
    };

    const onFilterChange = (key: keyof ContractorData | "zipradius", value?: string) => {
        let newFilter: Filter[];

        const index = selectedFilters.findIndex((f) => f.key === key);
        if (index > -1 && selectedFilters[index] && !isEmpty(value)) {
            selectedFilters[index].value = value as string;
        } else if (!isEmpty(value)) {
            selectedFilters.push({ key, value: value as string });
        } else if (isEmpty(value) && index > -1 && selectedFilters[index]) {
            selectedFilters.splice(index, 1);
        }
        newFilter = [...selectedFilters];

        setSelectedFilters(newFilter);
        return newFilter;
    };

    const onSelectFilterChange = (key: keyof ContractorData | "zipradius", value?: string, remove?: boolean) => {
        let newFilter: Filter[];
        if (remove) {
            newFilter = [...selectedFilters.filter((f) => !(f.key === key && f.value === value))];
        } else {
            newFilter = [...(selectedFilters ?? []), { key, value: value as string }];
        }

        setSelectedFilters(newFilter);
        return newFilter;
    };

    const onFilter = (filter: any) => {
        const searchAttr = getSearchAttr(filter, zipFilterActive);
        setAppliedFilters(filter);
        if (filter.length < 4) {
            setShowAllTags(false);
        }
        loadContractors(1, searchAttr, true, LoadingTypes.Loading);
    };

    const onEnter = (key: keyof ContractorData, event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const newFilter = onFilterChange(key, (event.target as HTMLInputElement).value);
            const errors = onValidateFilters(newFilter);
            if (isEmpty(errors)) {
                onFilter(newFilter);
            }
        }
    };

    const isFilterEmpty = isEmpty(selectedFilters);

    const filters = getFilters(contractorSearchParams, isLoadingParams, zipFilterActive);

    const onMapBackButtonClick = () => {
        setShowList(true);
        setShowMap(false);
    };

    return (
        <>
            <Container className="p-0">
                <Heading>{page?.title ?? "Contractor Search"}</Heading>
            </Container>
            <PageComponentsList components={topRows} />
            <ErrorSummary className="mt-3" errorSummary={errorSummary} />
            <Alert
                show={showLimitAlert}
                variant="info"
                className={cn("outline-0", {
                    "w-100": !isMobile,
                    "fixed-bottom mx-2": isMobile,
                })}
                dismissible
                onClick={() => {
                    setShowLimitAlert(false);
                }}
            >
                <Alert.Heading as="h4">
                    Showing {MAX_CONTRACTORS} of {contractorData?.totalRecords} contractors
                </Alert.Heading>
                <p>Can't view more than {MAX_CONTRACTORS} contractors at a time. To view other contractors please try using filters.</p>
            </Alert>
            {contractorOnSlideout && (
                <ContractorDetailsOffcanvas
                    contact={contractorOnSlideout}
                    onClose={() => {
                        contractorsWithMarkersRef.current
                            .find((c) => c.contractor?.contactNumber === contractorOnSlideout.contactNumber)
                            ?.marker?.setIcon(CustomMarker);
                        setContractorOnSlideout(null);
                    }}
                />
            )}
            <Container className="contractor-map-view p-0">
                <div className="d-flex main-container mt-3">
                    <div
                        id={LIST_CONTAINER_ID}
                        className={cn("list-container h-100", {
                            "d-none": !showList || (showMap && isMobile),
                        })}
                    >
                        <div className="h-100 d-flex flex-column">
                            {!hideFilters ? (
                                <>
                                    <div className="w-100 d-flex mb-2 pe-md-2">
                                        <Button onClick={() => setHideFilters(true)} variant="secondary">
                                            <Icon className="me-2" icon={["fal", "arrow-left"]} />
                                            Back
                                        </Button>
                                        <div className="d-flex ms-auto gap-2">
                                            <Button
                                                disabled={isLoading !== LoadingTypes.NotLoading || isFilterEmpty}
                                                className="me-auto"
                                                variant="link"
                                                size="sm"
                                                onClick={onClearFilter}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                disabled={isLoading !== LoadingTypes.NotLoading}
                                                size="sm"
                                                onClick={() => {
                                                    const errors = onValidateFilters(selectedFilters);
                                                    if (isEmpty(errors)) {
                                                        setPageNumber(1);
                                                        onFilter(selectedFilters);
                                                        setHideFilters(true);
                                                    }
                                                }}
                                            >
                                                Done
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap pe-md-2 overflow-auto">
                                        {filters.map((f, index) => {
                                            if (f.id === "filter-zip-radius") {
                                                return (
                                                    <SingleFilterSelectItem
                                                        disabled={isLoading !== LoadingTypes.NotLoading}
                                                        key={f.property}
                                                        onSelectValue={onFilterChange}
                                                        filter={selectedFilters}
                                                        {...f}
                                                    />
                                                );
                                            } else if (!isNil(f.options) || f.isLoadingOptions) {
                                                return (
                                                    <SingleFilterMultiSelectItem
                                                        disabled={isLoading !== LoadingTypes.NotLoading}
                                                        key={f.property}
                                                        onSelectValue={onSelectFilterChange}
                                                        filter={selectedFilters}
                                                        {...f}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <SingleFilterItem
                                                        className="w-100"
                                                        disabled={isLoading !== LoadingTypes.NotLoading}
                                                        key={f.property}
                                                        onFilterChange={onFilterChange}
                                                        onEnter={onEnter}
                                                        filter={selectedFilters}
                                                        errors={filterErrors}
                                                        {...f}
                                                    />
                                                );
                                            }
                                        })}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={cn("d-flex", {
                                            "pt-3": isMobile,
                                            "sidebar-buttons-mobile": isMobile,
                                        })}
                                    >
                                        <div className="flex-grow-1">
                                            <div className="d-flex  pe-md-2">
                                                <Button
                                                    variant="secondary"
                                                    disabled={isLoading !== LoadingTypes.NotLoading}
                                                    className={cn("text-nowrap w-md-100", {
                                                        "me-2": isMobile,
                                                        "flex-grow-1": isMobile,
                                                    })}
                                                    onClick={() => setHideFilters(!hideFilters)}
                                                >
                                                    <Icon className="me-2" icon={["fal", "filter"]} />
                                                    Filters
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    disabled={isLoading !== LoadingTypes.NotLoading}
                                                    className={cn("text-nowrap", {
                                                        "d-none": !isMobile,
                                                        "flex-grow-1": isMobile,
                                                    })}
                                                    onClick={() => {
                                                        if (!mapInitialized) {
                                                            setMapInitialized(true);
                                                        }
                                                        setShowMap(true);

                                                        setupUserLoaction();
                                                    }}
                                                >
                                                    <Icon className="me-2" icon={["fal", "location-dot"]} />
                                                    Map
                                                </Button>
                                            </div>

                                            <div
                                                className={cn("d-flex flex-wrap pe-md-2 mt-2", {
                                                    "flex-column": isMobile,
                                                })}
                                            >
                                                {filters.slice(0, 2).map((f, index) => {
                                                    if (!isNil(f.options) || f.isLoadingOptions) {
                                                        return (
                                                            <SingleFilterMultiSelectItem
                                                                disabled={isLoading !== LoadingTypes.NotLoading}
                                                                key={f.property}
                                                                onSelectValue={(
                                                                    key: keyof ContractorData | "zipradius",
                                                                    value?: string,
                                                                    remove?: boolean
                                                                ) => {
                                                                    const newFilter = onSelectFilterChange(key, value, remove);
                                                                    const errors = onValidateFilters(newFilter);
                                                                    if (isEmpty(errors)) {
                                                                        onFilter(newFilter);
                                                                    }
                                                                }}
                                                                filter={appliedFilters}
                                                                {...f}
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <SingleFilterItem
                                                                className="w-100"
                                                                disabled={isLoading !== LoadingTypes.NotLoading}
                                                                key={f.property}
                                                                onFilterChange={onFilterChange}
                                                                onEnter={onEnter}
                                                                filter={selectedFilters}
                                                                {...f}
                                                            />
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div
                                            className={cn({
                                                "scrollbar-offset": !isMobile,
                                            })}
                                        ></div>
                                    </div>
                                    <div
                                        className={cn("d-flex mb-2 flex-row flex-wrap align-items-center gap-2 pe-md-2", {
                                            "overflow-auto mb-auto position-relative": showAllTags,
                                            "scrollbar-offset": !showAllTags,
                                        })}
                                    >
                                        <div
                                            className={cn("d-flex flex-row gap-2 flex-wrap", {
                                                "mt-2": appliedFilterCount > 0,
                                            })}
                                        >
                                            {(!showAllTags ? appliedFilters.slice(0, 3) : appliedFilters).map((f, index) => (
                                                <Tag
                                                    key={index}
                                                    title={filterTitles[f.key] ?? capitalize(f.key)}
                                                    value={f.value ?? ""}
                                                    onRemove={() => {
                                                        const newFilter = selectedFilters.filter(
                                                            (sf) => !(sf.key === f.key && sf.value === f.value)
                                                        );
                                                        setSelectedFilters(newFilter);
                                                        onFilter(newFilter);
                                                    }}
                                                />
                                            ))}
                                            {appliedFilterCount > 3 && !showAllTags && (
                                                <Button
                                                    aria-expanded="false"
                                                    className="btn-fixed-medium-size bg-transparent text-body show-filter-tags-button d-flex flex-row justify-content-center gap-1 small border rounded-1 px-2 py-1 w-100"
                                                    onClick={() => setShowAllTags(true)}
                                                >{`Show ${appliedFilterCount - 3} more filter${
                                                    appliedFilterCount - 3 > 1 ? "s" : ""
                                                }`}</Button>
                                            )}
                                            {showAllTags && (
                                                <div className="w-100 collapse-tag-container sticky-bottom">
                                                    <Button
                                                        aria-expanded="true"
                                                        className="btn-fixed-medium-size bg-transparent text-body d-flex flex-row justify-content-center gap-1 small border rounded-1 px-2 py-1 w-100"
                                                        onClick={() => setShowAllTags(false)}
                                                        aria-label="Collapse filter tags and show list"
                                                    >
                                                        Collapse
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {isLoading !== LoadingTypes.Loading && (
                                        <div
                                            className={cn("mt-1 position-relative pe-md-2 h-100", {
                                                scrollbox: !isMobile,
                                                "d-none": showAllTags,
                                            })}
                                        >
                                            <div className="scrollbox-content d-flex flex-column gap-2">
                                                {contractorData?.items &&
                                                    !contractorData?.items?.length &&
                                                    isLoading === LoadingTypes.NotLoading && (
                                                        <div className="w-100 text-center mt-5 d-flex justify-content-center overflow-visible">
                                                            <span>No results found.</span>{" "}
                                                            <Button
                                                                onClick={onClearFilter}
                                                                className={cn("border-0 p-0 ms-1 fs-6", { "d-none": !appliedFilterCount })}
                                                                variant="link"
                                                            >
                                                                Clear filters.
                                                            </Button>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="list-button-container position-sticky position-sticky-top-0 mb-2 w-100 d-flex flex-column">
                                                {contractorData?.items?.map((c, index) => {
                                                    return (
                                                        <div key={c.contactNumber} className="pb-3">
                                                            <Card>
                                                                <Card.Body>
                                                                    <ContractorCard
                                                                        key={c.contactNumber + index}
                                                                        c={c}
                                                                        showOnMapMobile={showOnMapMobile}
                                                                        isLoading={isLoading !== LoadingTypes.NotLoading}
                                                                        contractorsWithMarkersRef={contractorsWithMarkersRef}
                                                                        mapRef={mapRef}
                                                                        openedInfoWindowRef={openedInfoWindowRef}
                                                                        setSelectedContractor={setSelectedContractor}
                                                                        setContractorOnSlideout={setContractorOnSlideout}
                                                                    />
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {isLoading === LoadingTypes.Loading && !!programNumber && (
                                <div
                                    className={cn("m-auto", {
                                        "d-none": showAllTags,
                                    })}
                                >
                                    <Spinner className="m-4" animation="border" role="status">
                                        <span className="visually-hidden">Loading contractor list...</span>
                                    </Spinner>
                                </div>
                            )}
                            {hideFilters && (
                                <div
                                    className={cn("pe-md-2 d-flex", {
                                        "scrollbar-offset": !isMobile,
                                    })}
                                >
                                    <SubmitButton
                                        variant="secondary"
                                        disabled={
                                            isLoading !== LoadingTypes.NotLoading ||
                                            !loadMoreEnabled ||
                                            (contractorData?.items.length ?? 0) >= MAX_CONTRACTORS
                                        }
                                        isSubmitting={isLoading === LoadingTypes.LazyLoading}
                                        className={cn("", {
                                            "d-none":
                                                isMobile &&
                                                (isLoading === LoadingTypes.Loading || !contractorData?.items?.length || showAllTags),
                                            "w-100": !isMobile,
                                            "m-auto": isMobile,
                                            "mt-3": !isMobile,
                                        })}
                                        spinnerText="Loading more contracors..."
                                        onClick={() => {
                                            setPageNumber(pageNumber + 1);
                                            loadContractors(
                                                pageNumber + 1,
                                                getSearchAttr(selectedFilters, zipFilterActive),
                                                false,
                                                LoadingTypes.LazyLoading
                                            );
                                        }}
                                    >
                                        Load more
                                    </SubmitButton>
                                </div>
                            )}
                        </div>
                    </div>

                    {mapInitialized && (
                        <div
                            className={cn("map-inner-container overflow-hidden", {
                                "flex-1": !showList,
                                "ms-2": !showList || !isMobile,
                            })}
                        >
                            <div
                                className={cn("d-flex flex-column h-100 position-relative", {
                                    "d-none": !showMap,
                                    "full-screen-map overflow-hidden position-fixed d-block": isMobile && showMap,
                                })}
                            >
                                <div
                                    className={cn("map-top-buttons-desktop d-flex", {
                                        "d-none": isMobile,
                                    })}
                                >
                                    <Button
                                        className="ms-2 mt-2 border-0 btn-fixed-medium-size"
                                        variant="secondary"
                                        onClick={() => (isMobile ? onMapBackButtonClick() : setShowList(!showList))}
                                        aria-label={showList ? "Hide side panel" : "Show side panel"}
                                        aria-controls={LIST_CONTAINER_ID}
                                    >
                                        {showList ? (
                                            <Icon icon={"arrow-left"} />
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={"arrow-right"} /> Show list
                                            </>
                                        )}
                                    </Button>
                                </div>
                                <div
                                    className={cn("map-top-buttons w-100 d-flex", {
                                        "d-none": !isMobile,
                                    })}
                                    style={fontSize === TypographyFontSize.Large ? { height: "4.5rem" } : { height: "3.875rem" }}
                                >
                                    <Button
                                        variant="secondary"
                                        aria-label="Show filters"
                                        className={cn("ms-2 my-auto", {
                                            "d-none": !isMobile,
                                        })}
                                        onClick={() => {
                                            setHideFilters(false);
                                            setShowMap(false);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={["fal", "filter"]} /> Filters{" "}
                                        {appliedFilterCount > 0 ? `(${appliedFilterCount})` : ""}
                                    </Button>
                                    <SubmitButton
                                        variant="outline-secondary"
                                        disabled={
                                            isLoading !== LoadingTypes.NotLoading ||
                                            !loadMoreEnabled ||
                                            (contractorData?.items.length ?? 0) >= MAX_CONTRACTORS
                                        }
                                        isSubmitting={isLoading === LoadingTypes.LazyLoading}
                                        className={cn("ms-2 my-auto", {
                                            "d-none": !isMobile,
                                        })}
                                        spinnerText="Loading more contracors..."
                                        onClick={() =>
                                            loadContractors(
                                                pageNumber + 1,
                                                getSearchAttr(selectedFilters, zipFilterActive),
                                                false,
                                                LoadingTypes.LazyLoading
                                            )
                                        }
                                    >
                                        Load more
                                    </SubmitButton>
                                    <Button
                                        className="my-auto ms-auto me-2"
                                        variant="secondary"
                                        onClick={() => (isMobile ? onMapBackButtonClick() : setShowList(!showList))}
                                        aria-label="Hide map"
                                    >
                                        <Icon icon={["fal", "arrow-down-left-and-arrow-up-right-to-center"]} />
                                    </Button>
                                </div>
                                <div className={cn("mb-0 d-flex flex-column d-flex h-100", {})}>
                                    <div className="h-100">
                                        <ContractorMap
                                            selectedContractor={selectedContractor}
                                            setSelectedContractor={setSelectedContractor}
                                            mapRef={mapRef}
                                            mapInitialisationResolver={mapInitialisationResolver}
                                            firstMapLoadFinished={firstMapLoadFinished}
                                            setContractorOnSlideout={setContractorOnSlideout}
                                            clustererRef={clustererRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
            <PageComponentsList components={bottomRows} />
        </>
    );
};
