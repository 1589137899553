import { APP_LIST_GRID_ID, getQueryParams, getSearchParams, useApplicationList } from "./useApplicationList";
import { useState, useMemo, useContext, useCallback } from "react";
import { dateToJson, formatDateAndTime, formatJsonDate, getBrowserTimezoneOffset } from "components/utils/date";
import { useGridConfig } from "components/utils/useGridConfig";
import { chunk, isEmpty, isNil } from "lodash";
import { getColumnSortState, getVisibleColumns } from "components/DataGrid/utils";
import { AppContext } from "components/App/AppContext";
import {
    ApplicationListColumnKeys,
    CustomerDashboardModel,
    DateRanges,
    FilterButtonState,
    FilterParams,
    SortOptions,
    FilterPair,
} from "./types";
import { DataGridCellValue, DataGridColumnConfig, DataGridConfig, DataGridRowConfig } from "components/DataGrid/types";
import { useCustomerPortalDetails } from "./useCustomerPortalDetails";
import { isInIframe } from "components/utils/dom";
import { ErrorSummaryInterface } from "types/ErrorSummary";
import { getUrl, httpPostAuthorized, httpPutAuthorized } from "components/utils/http";
import { downloadCSV } from "components/utils/files";
import { Icon } from "components/Icon";
import { toArray } from "components/utils/array";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmptyFilteredGrid, EmptyGrid } from "./EmptyStateScreen";
import { useToast } from "components/Toast";

export const useCustomerDashboardModel = (): CustomerDashboardModel => {
    const { programNumber } = useContext(AppContext);
    const toast = useToast();

    const timeOffset = getBrowserTimezoneOffset();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string>("");
    const [sortAsc, setSortAsc] = useState<boolean>(true);

    const [selectedSearchOption, setSelectedSearchOption] = useState(SEARCH_COLUMNS[0]);
    const searchOptions = SEARCH_COLUMNS.filter((column) => column.key !== selectedSearchOption.key);

    const [filterPairs, setFilterPairs] = useState<FilterPair[]>([]);
    const [filterButtonState, setFilterButtonState] = useState<FilterButtonState>(FilterButtonState.AllApps);

    const [withToDoTasks, setWithToDoTasks] = useState<boolean>(false);
    const [withUnreadMessages, setWithUnreadMessages] = useState<boolean>(false);

    const [selectedCreatedDate, setSelectedCreatedDate] = useState<string>(DateRanges.Created);
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();

    const [statuses, setStatuses] = useState<string[]>([]);

    const [errorSummary, setErrorSummary] = useState<ErrorSummaryInterface>();
    const [isExporting, setIsExporting] = useState(false);
    const [isDeletingApplication, setIsDeletingApplication] = useState(false);

    const [customerPortalDetails, isLoadingCustomerPortalDetails, customerPortalDetailsError, refreshCustomerPortalDetails] =
        useCustomerPortalDetails(programNumber);
    const [gridConfig, isLoadingGridConfig] = useGridConfig(APP_LIST_GRID_ID);

    const [gridResponse, isLoadingApplications, error, refreshApplicationList] = useApplicationList(
        programNumber,
        filterPairs,
        pageNumber,
        pageSize,
        sortBy,
        sortAsc,
        timeOffset
    );

    const isLoadingDashboard = isLoadingGridConfig || isLoadingCustomerPortalDetails;

    const applications = gridResponse?.items;
    const savedApplicationsCount = customerPortalDetails?.savedApplicationsCount ?? 0;
    const completedApplicationsCount = customerPortalDetails?.completedApplicationsCount ?? 0;
    const favoriteApplicationsCount = customerPortalDetails?.favoriteApplicationsCount ?? 0;
    const applicationStatuses = customerPortalDetails?.applicationStatusList ?? [];

    const activeSortOption = useMemo(() => {
        if (sortBy === ApplicationListColumnKeys.DateCreated) {
            return sortAsc ? SortOptions.Oldest : SortOptions.Newest;
        }

        if (sortBy === ApplicationListColumnKeys.Favorite) {
            return SortOptions.BookmarkedFirst;
        }

        return undefined;
    }, [sortBy, sortAsc]);

    const onDateRangeChange = useCallback((dateRange: string) => {
        setSelectedCreatedDate(dateRange);

        if (dateRange !== DateRanges.Custom) {
            const range = getDateRange(dateRange);
            setStartDate(range.startDate);
            setEndDate(range.endDate);
        }
    }, []);

    const onStartDateChange = useCallback((date: string) => {
        setSelectedCreatedDate(DateRanges.Custom);
        setStartDate(date);
    }, []);

    const onEndDateChange = useCallback((date: string) => {
        setSelectedCreatedDate(DateRanges.Custom);
        setEndDate(date);
    }, []);

    const onSortChange = (key: string) => {
        if (!isEmpty(sortBy)) {
            if (sortBy === key) {
                setSortAsc((prev) => !prev);
                if (sortAsc) {
                    setSortAsc(false);
                } else {
                    setSortBy("");
                    setSortAsc(true);
                }
            } else {
                setSortBy(key);
                setSortAsc(true);
            }
        } else {
            setSortBy(key);
            setSortAsc(true);
        }
    };

    const sort = (sorOption: string) => {
        switch (sorOption) {
            case SortOptions.Newest:
                setSortBy(ApplicationListColumnKeys.DateCreated);
                setSortAsc(false);
                break;
            case SortOptions.Oldest:
                setSortBy(ApplicationListColumnKeys.DateCreated);
                setSortAsc(true);
                break;
            case SortOptions.BookmarkedFirst:
                setSortBy(ApplicationListColumnKeys.Favorite);
                setSortAsc(false);
                break;
            default:
                break;
        }
    };

    const onFilterButtonStateChange = (state: FilterButtonState) => {
        let pairs = [...filterPairs];

        // Remove existing filter pairs
        pairs = pairs.filter((f) => f.field !== ApplicationListColumnKeys.Status && f.field !== ApplicationListColumnKeys.Favorite);

        if (state === FilterButtonState.FavoriteApps) {
            pairs.push({
                field: ApplicationListColumnKeys.Favorite,
                displayValue: "yes",
                value: "1",
                title: "Bookmarked",
            });
        }

        if (state === FilterButtonState.IncompleteApps) {
            pairs.push({
                field: ApplicationListColumnKeys.Status,
                displayValue: "yes",
                value: "incomplete",
                title: "Incomplete",
            });
        }

        setPageNumber(1);
        setFilterButtonState(state);
        setFilterPairs([...pairs]);
    };

    const onSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // get the search input value from form data
        const formData = new FormData(event.currentTarget);
        const searchInput = (formData.get("search") as string) ?? "";
        const value = searchInput.trim();

        // do nothing if the search input is empty
        if (!value) {
            return;
        }

        const field = selectedSearchOption.key;

        const existingFilter = filterPairs.find((f) => f.field === field);
        if (existingFilter) {
            existingFilter.value = value;
        } else {
            filterPairs.push({
                field,
                value,
                title: selectedSearchOption.name,
            });
        }

        setPageNumber(1);
        setFilterPairs([...filterPairs]);

        // reset the search input
        const searchInputControl = event.currentTarget.elements.namedItem("search") as HTMLInputElement;
        searchInputControl.value = "";
        setSelectedSearchOption(SEARCH_COLUMNS[0]);
    };

    const onToDoTasksChange = (isEnabled: boolean) => {
        setWithToDoTasks(isEnabled);

        // Remove existing filter pair
        const pairs = filterPairs.filter((f) => f.field !== ApplicationListColumnKeys.Tasks);

        if (isEnabled) {
            pairs.push({
                field: ApplicationListColumnKeys.Tasks,
                displayValue: "yes",
                value: "1",
                title: "To Do Tasks",
            });
        }

        setPageNumber(1);
        setFilterPairs([...pairs]);
    };

    const applyFilters = (filters: FilterParams | undefined) => {
        let pairs = [...filterPairs];

        // Remove Unread messages filter pair
        pairs = pairs.filter((f) => f.field !== ApplicationListColumnKeys.Messages);

        // Remove To Do Tasks filter pair
        pairs = pairs.filter((f) => f.field !== ApplicationListColumnKeys.Tasks);

        // Remove exiting date filter pairs
        pairs = pairs.filter((f) => f.field !== ApplicationListColumnKeys.DateCreated);

        // Remove exiting status filter pairs
        pairs = pairs.filter((f) => f.field !== ApplicationListColumnKeys.AppStatus);

        // Update state from filters parameter
        if (filters) {
            setWithUnreadMessages(filters.withUnreadMessages);
            setStatuses(filters.statuses);
            setStartDate(filters.startDate);
            setEndDate(filters.endDate);
            setSelectedCreatedDate(filters.selectedCreatedDate);
        }

        if (filters?.withUnreadMessages ?? withUnreadMessages) {
            pairs.push({
                field: ApplicationListColumnKeys.Messages,
                displayValue: "yes",
                value: "1",
                title: "Unread Messages",
            });
        }

        if (withToDoTasks) {
            pairs.push({
                field: ApplicationListColumnKeys.Tasks,
                displayValue: "yes",
                value: "1",
                title: "To Do Tasks",
            });
        }

        const currentStartDate = filters?.startDate ?? startDate;
        const currentEndDate = filters?.endDate ?? endDate;

        if (!isEmpty(currentStartDate) || !isEmpty(currentEndDate)) {
            if (isEmpty(currentEndDate)) {
                pairs.push({
                    field: ApplicationListColumnKeys.DateCreated,
                    displayValue: formatJsonDate(currentStartDate),
                    title: "Created starting from",
                    value: currentStartDate!,
                });
            } else if (currentStartDate === currentEndDate) {
                pairs.push({
                    field: ApplicationListColumnKeys.DateCreated,
                    displayValue: formatJsonDate(currentStartDate),
                    title: "Created on",
                    value: `${currentStartDate}|${currentEndDate}`,
                });
            } else {
                pairs.push({
                    field: ApplicationListColumnKeys.DateCreated,
                    displayValue: `${formatJsonDate(currentStartDate)} - ${formatJsonDate(currentEndDate)}`,
                    title: "Created between",
                    value: `${currentStartDate}|${currentEndDate}`,
                });
            }
        }

        (filters?.statuses ?? statuses).forEach((status) => {
            pairs.push({
                field: ApplicationListColumnKeys.AppStatus,
                value: status,
                title: "Status",
            });
        });

        setPageNumber(1);
        setFilterPairs([...pairs]);
    };

    const removeFilterPair = (filterPair: FilterPair) => {
        const index = filterPairs.findIndex((f) => f.field === filterPair.field && f.value === filterPair.value);
        if (index >= 0) {
            filterPairs.splice(index, 1);
            setPageNumber(1);
            setFilterPairs([...filterPairs]);

            // remove the favorite filter pair
            if (filterPair.field === ApplicationListColumnKeys.Favorite) {
                setFilterButtonState(FilterButtonState.AllApps);
            }

            // remove the incomplete filter pair
            if (filterPair.field === ApplicationListColumnKeys.Status) {
                setFilterButtonState(FilterButtonState.AllApps);
            }

            // remove the to do tasks filter pair
            if (filterPair.field === ApplicationListColumnKeys.Tasks) {
                setWithToDoTasks(false);
            }

            // remove the unread messages filter pair
            if (filterPair.field === ApplicationListColumnKeys.Messages) {
                setWithUnreadMessages(false);
            }

            // remove the start date filter pair
            if (filterPair.field === ApplicationListColumnKeys.DateCreated) {
                onDateRangeChange(DateRanges.Created);
            }

            // remove the status from the statuses array
            if (filterPair.field === ApplicationListColumnKeys.AppStatus) {
                const statuses = [...filterPairs.filter((f) => f.field === ApplicationListColumnKeys.AppStatus).map((f) => f.value)];
                setStatuses(statuses);
            }
        }
    };

    const onResetFilters = useCallback(() => {
        setPageNumber(1);
        setFilterPairs([]);
        setWithToDoTasks(false);
        setWithUnreadMessages(false);
        onDateRangeChange(DateRanges.Created);
        setStatuses([]);
        setFilterButtonState(FilterButtonState.AllApps);
    }, [onDateRangeChange]);

    const onExportData = async () => {
        let url: any = null;
        const data = [] as any;

        const baseUrl =
            programNumber && applicationsGridConfig && applicationsGridConfig?.pageSize > 0
                ? getUrl(process.env.REACT_APP_APPLICATION_GRIDV2_ENDPOINT, { programNumber })
                : null;

        if (baseUrl) {
            url = baseUrl + "?" + getQueryParams(1, 10000, getBrowserTimezoneOffset()).toString();
        }

        if (url) {
            try {
                setIsExporting(true);
                setErrorSummary(undefined);

                const body = getSearchParams(filterPairs, sortBy, sortAsc);

                const response = await httpPostAuthorized(url, body);

                const gridRows = toArray(response.grid.rows);
                const columns = getVisibleColumns(applicationsGridConfig);

                const rowData = [] as any;

                // Get header row
                const header = columns.map((c) => c.name);

                // Get row contents
                const rowContent = columns.map((c) => {
                    return gridRows
                        .filter((r) => r)
                        .map((r) => {
                            if (c.key === ApplicationListColumnKeys.Favorite) {
                                return Number(r[c.key]) === 1 ? "Yes" : "No";
                            }

                            return r && r[c.key];
                        });
                });

                for (let i = 0; i < gridRows.length; i++) {
                    rowContent.forEach((r) => {
                        rowData.push(r[i]);
                    });
                }

                const chunkedRows = [...chunk(rowData, columns.length)];

                // Push into data array for the export
                data.push(header, ...chunkedRows);

                downloadCSV(data, `dashboard-applications-list-${programNumber}`);
            } catch (error: any) {
                setErrorSummary(error);
            } finally {
                setIsExporting(false);
            }
        }
    };

    const onDeleteApplication = async (appId: string) => {
        try {
            setIsDeletingApplication(true);
            setErrorSummary(undefined);

            const url: any = getUrl(process.env.REACT_APP_APPLICATION_REMOVE_ENDPOINT, { appId });
            const response = await httpPutAuthorized(url);

            if (applications && applications.length === 1) {
                setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1);
            }

            await refreshApplicationList();
            await refreshCustomerPortalDetails(programNumber);
            toast.success(response.responseMessage);
        } catch (error: any) {
            setErrorSummary(error);
        } finally {
            setIsDeletingApplication(false);
        }
    };

    const applicationsGridConfig: DataGridConfig | undefined = useMemo(() => {
        if ((isNil(applications) && isLoadingApplications) || isNil(gridConfig)) {
            return undefined;
        }
        const columns = gridConfig?.columns.map((c) => createColumn(c as DataGridColumnConfig, sortBy, sortAsc));
        const messagesTasksColumn = columns.find((c) => c.key === ApplicationListColumnKeys.MessagesAndTasks);
        messagesTasksColumn!.hideHeader = true;

        return {
            ...gridConfig,
            columns,
            rows:
                applications?.map((i) => ({
                    data: i,
                    actions: [],
                })) ?? [],
            pageNumber,
            pageSize,
            pagesCount: Math.ceil((gridResponse?.totalRecords ?? 0) / pageSize),
            totalRecords: gridResponse?.totalRecords ?? 0,
            nothingFoundMessage: filterPairs.length > 0 ? <EmptyFilteredGrid onResetFilters={onResetFilters} /> : <EmptyGrid />,
        };
    }, [
        applications,
        isLoadingApplications,
        gridConfig,
        pageNumber,
        pageSize,
        gridResponse?.totalRecords,
        filterPairs.length,
        onResetFilters,
        sortBy,
        sortAsc,
    ]);

    if (isInIframe()) {
        return {
            ...modelForPortalBuilderPreview,
            applicationsGridConfig: {
                ...modelForPortalBuilderPreview.applicationsGridConfig,
                columns: modelForPortalBuilderPreview.applicationsGridConfig!.columns.map((c) => createColumn(c, "", false)),
                pageNumber: 1,
                pageSize: 2,
                pagesCount: 20,
                totalRecords: 40,
                nothingFoundMessage: "No applications found",
                notClearableFilterKeys: ["c_0939d01c-63e0-457f-b437-422c7bf6bfe0"],
            },
        };
    }

    return {
        isLoadingDashboard,
        isLoadingApplications,
        isExporting,
        isDeletingApplication,
        applicationsGridConfig,
        applicationCount: savedApplicationsCount + completedApplicationsCount,
        favoriteApplicationsCount,
        totalRebate: customerPortalDetails?.totalRebate ?? 0,
        totalNotifications: customerPortalDetails?.totalNotifications ?? 0,
        totalNotes: customerPortalDetails?.totalNotes ?? 0,
        totalTasks: customerPortalDetails?.totalTasks ?? 0,
        savedApplicationsCount,
        completedApplicationsCount,
        error,
        customerPortalDetailsError,
        errorSummary,

        searchOptions,
        selectedSearchOption,
        setSelectedSearchOption,
        onSearch,

        withToDoTasks,
        onToDoTasksChange,

        withUnreadMessages,
        setWithUnreadMessages,

        selectedCreatedDate,
        onDateRangeChange,

        startDate,
        onStartDateChange,

        endDate,
        onEndDateChange,

        statuses,
        setStatuses,

        filterButtonState,
        filterPairs,
        removeFilterPair,

        applyFilters,
        sort,
        activeSortOption,

        applicationStatuses,
        onFilterButtonStateChange,
        onResetFilters,
        onSortChange,
        onPageChange: setPageNumber,
        onPageSizeChange: setPageSize,
        onExportData,
        onDeleteApplication,
        programNumber,
    };
};

const SEARCH_COLUMNS = [
    {
        key: "*",
        name: "All",
    },
    {
        key: ApplicationListColumnKeys.ProjectNumber,
        name: "Project number",
    },
    {
        key: ApplicationListColumnKeys.ProjectName,
        name: "Project name",
    },
    {
        key: ApplicationListColumnKeys.Program,
        name: "Program name",
    },
    {
        key: ApplicationListColumnKeys.Contacts,
        name: "Contact",
    },
];

const modelForPortalBuilderPreview: CustomerDashboardModel = {
    isLoadingDashboard: false,
    isLoadingApplications: false,
    isExporting: false,
    isDeletingApplication: false,
    applicationsGridConfig: {
        columns: [
            {
                key: "c_d464633e-eb09-47d9-8aa7-310e69d9bee8",
                type: "text",
                name: "fav",
                order: "1000",
                sort: undefined,
                filter: "false",
                active: "true",
                hidecolumn: "false",
                datatype: "number",
            },
            {
                key: "c_051a4456-ab77-436f-8e17-81d137e0d3ec",
                type: "text",
                name: "project number",
                order: "1001",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "false",
                datatype: "varchar",
            },
            {
                key: "c_8c5a9650-feb3-4762-b4f4-2edb716a9c5d",
                type: "text",
                name: "contact",
                order: "1002",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "false",
                datatype: "varchar",
            },
            {
                key: "c_3ab0c969-4bb8-4316-8e13-c37247c8af42",
                type: "text",
                name: "date created",
                order: "1003",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "false",
                datatype: "datetime",
            },
            {
                key: "c_84f002ef-9fa3-471b-8efe-c2eaaeecd4d5",
                type: "text",
                name: "program",
                order: "1004",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "false",
                datatype: "varchar",
            },
            {
                key: "c_5255f16d-c125-40a4-821c-497cdd848f82",
                type: "text",
                name: "status",
                order: "1005",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "false",
                datatype: "varchar",
            },
            {
                key: "c_b1d1937c-dc3e-4fdb-a3c3-5af787aef7d9",
                type: "text",
                name: "messages & tasks",
                order: "1006",
                sort: "DESC",
                filter: "false",
                active: "true",
                hidecolumn: "false",
                datatype: "varchar",
            },
            {
                key: "c_26b6e2f0-13b7-4279-9c46-736033df03d4",
                type: "text",
                name: "initialpagenumber",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "true",
                datatype: "varchar",
            },
            {
                key: "c_19e30ba7-79a5-4896-9b4d-a650c5856b28",
                type: "text",
                name: "unread messages",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "true",
                datatype: "number",
            },
            {
                key: "c_4984e9b0-d234-43e3-abaa-d9ab34f239d8",
                type: "text",
                name: "numericstatus",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "false",
                hidecolumn: "true",
                datatype: "number",
            },
            {
                key: "c_7ac6e76b-c3a4-4e96-ad2c-ee9ecfa53aef",
                type: "text",
                name: "project name",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "true",
                datatype: "varchar",
            },
            {
                key: "c_db69ebe4-bcdc-49b3-8671-4b56c3e1ea66",
                type: "text",
                name: "customerid",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "true",
                datatype: "number",
            },
            {
                key: "c_4c578a4a-e1d2-41cc-b6b2-2de7d6415704",
                type: "text",
                name: "outstanding tasks",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "true",
                datatype: "number",
            },
            {
                key: "c_cc5b0593-1ef0-4a78-8689-3f9f917f3627",
                type: "text",
                name: "customernumber",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "true",
                datatype: "varchar",
            },
            {
                key: "c_0939d01c-63e0-457f-b437-422c7bf6bfe0",
                type: "text",
                name: "applicationstatus",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "true",
                hidecolumn: "true",
                datatype: "varchar",
            },
            {
                key: "c_bfbce9eb-03bb-42dc-b4af-46052cc0cfe1",
                type: "text",
                name: "appid",
                order: "1007",
                sort: undefined,
                filter: "true",
                active: "false",
                hidecolumn: "true",
                datatype: "varchar",
            },
        ],
        rows: [
            {
                data: {
                    "c_db69ebe4-bcdc-49b3-8671-4b56c3e1ea66": "11288829",
                    "c_cc5b0593-1ef0-4a78-8689-3f9f917f3627": "A78944E3-5050-439C-8710-2AFB084319B6",
                    "c_bfbce9eb-03bb-42dc-b4af-46052cc0cfe1": "3454A328595947B89CBCAAAE6670F639",
                    "c_051a4456-ab77-436f-8e17-81d137e0d3ec": "DMCCCR1234567890",
                    "c_8c5a9650-feb3-4762-b4f4-2edb716a9c5d": null,
                    "c_0939d01c-63e0-457f-b437-422c7bf6bfe0": "submitted",
                    "c_4984e9b0-d234-43e3-abaa-d9ab34f239d8": "21063",
                    "c_5255f16d-c125-40a4-821c-497cdd848f82": "Application Received",
                    "c_3ab0c969-4bb8-4316-8e13-c37247c8af42": "2022-03-29T12:32:57.137",
                    "c_19e30ba7-79a5-4896-9b4d-a650c5856b28": "0",
                    "c_4c578a4a-e1d2-41cc-b6b2-2de7d6415704": "2",
                    "c_84f002ef-9fa3-471b-8efe-c2eaaeecd4d5": "Sample Program",
                    "c_26b6e2f0-13b7-4279-9c46-736033df03d4": "4A222E90CCC111EABA98058BF65FA3BD",
                    "c_b1d1937c-dc3e-4fdb-a3c3-5af787aef7d9": "Messages:1\r\nTasks:2",
                },
                actions: [],
            },
            {
                data: {
                    "c_db69ebe4-bcdc-49b3-8671-4b56c3e1ea66": "11288829",
                    "c_cc5b0593-1ef0-4a78-8689-3f9f917f3627": "A78944E3-5050-439C-8710-2AFB084319B6",
                    "c_bfbce9eb-03bb-42dc-b4af-46052cc0cfe1": "8E4FDB0D2B6444C8B6AD23FBAE8CE673",
                    "c_051a4456-ab77-436f-8e17-81d137e0d3ec": "DMCCCR1234567880",
                    "c_8c5a9650-feb3-4762-b4f4-2edb716a9c5d": null,
                    "c_0939d01c-63e0-457f-b437-422c7bf6bfe0": "submitted",
                    "c_4984e9b0-d234-43e3-abaa-d9ab34f239d8": "21063",
                    "c_5255f16d-c125-40a4-821c-497cdd848f82": "Application Received",
                    "c_3ab0c969-4bb8-4316-8e13-c37247c8af42": "2022-04-13T09:14:54.587",
                    "c_19e30ba7-79a5-4896-9b4d-a650c5856b28": "0",
                    "c_4c578a4a-e1d2-41cc-b6b2-2de7d6415704": "1",
                    "c_84f002ef-9fa3-471b-8efe-c2eaaeecd4d5": "Sample Program",
                    "c_26b6e2f0-13b7-4279-9c46-736033df03d4": "4A222E90CCC111EABA98058BF65FA3BD",
                    "c_b1d1937c-dc3e-4fdb-a3c3-5af787aef7d9": "Messages:0\r\nTasks:1",
                },
                actions: [],
            },
            {
                data: {
                    "c_db69ebe4-bcdc-49b3-8671-4b56c3e1ea66": "11288829",
                    "c_cc5b0593-1ef0-4a78-8689-3f9f917f3627": "A04244E3-5050-439C-8710-2AFB084319B6",
                    "c_bfbce9eb-03bb-42dc-b4af-46052cc0cfe1": "144E579235304A8F9C17D707B01ABD00",
                    "c_051a4456-ab77-436f-8e17-81d137e0d3ec": "DMCCCR1539307097",
                    "c_8c5a9650-feb3-4762-b4f4-2edb716a9c5d": null,
                    "c_0939d01c-63e0-457f-b437-422c7bf6bfe0": "submitted",
                    "c_4984e9b0-d234-43e3-abaa-d9ab34f239d8": "21063",
                    "c_5255f16d-c125-40a4-821c-497cdd848f82": "Application Received",
                    "c_3ab0c969-4bb8-4316-8e13-c37247c8af42": "2022-03-01T09:55:43.483",
                    "c_19e30ba7-79a5-4896-9b4d-a650c5856b28": "0",
                    "c_4c578a4a-e1d2-41cc-b6b2-2de7d6415704": "1",
                    "c_84f002ef-9fa3-471b-8efe-c2eaaeecd4d5": "Sample Program",
                    "c_26b6e2f0-13b7-4279-9c46-736033df03d4": "4A222E90CCC111EABA98058BF65FA3BD",
                    "c_b1d1937c-dc3e-4fdb-a3c3-5af787aef7d9": "Messages:0\r\nTasks:1",
                },
                actions: [],
            },
        ],
        filter: "",
        pageNumber: 1,
        pageSize: 2,
        pagesCount: 20,
        totalRecords: 40,
        nothingFoundMessage: "No applications found",
        notClearableFilterKeys: ["c_0939d01c-63e0-457f-b437-422c7bf6bfe0"],
    },
    applicationCount: 3,
    totalRebate: 1000,
    totalNotifications: 27,
    totalNotes: 13,
    totalTasks: 14,
    savedApplicationsCount: 0,
    completedApplicationsCount: 3,
    favoriteApplicationsCount: 2,
    error: undefined,
    errorSummary: undefined,

    searchOptions: [],
    selectedSearchOption: SEARCH_COLUMNS[0],
    setSelectedSearchOption: () => {},
    onSearch: () => {},

    withToDoTasks: false,
    onToDoTasksChange: () => {},

    withUnreadMessages: false,
    setWithUnreadMessages: () => {},

    selectedCreatedDate: DateRanges.Created,
    onDateRangeChange: () => {},

    startDate: undefined,
    onStartDateChange: () => {},

    endDate: undefined,
    onEndDateChange: () => {},

    statuses: [],
    setStatuses: () => {},

    filterButtonState: FilterButtonState.AllApps,
    filterPairs: [],
    removeFilterPair: () => {},

    applyFilters: () => {},
    sort: () => {},
    activeSortOption: undefined,

    applicationStatuses: [],
    onFilterButtonStateChange: () => {},
    onResetFilters: () => {},
    onSortChange: () => {},
    onPageChange: () => {},
    onExportData: async () => {},
    onDeleteApplication: async () => {},
    programNumber: undefined,
};

const createColumn = (c: DataGridColumnConfig, sortBy: string, sortAsc: boolean) =>
    ({
        ...c,
        sort: getColumnSortState(c, sortBy, sortAsc),
        className: getColumnClassName(c),
        renderer: getColumnRenderer(c),
    } as DataGridColumnConfig);

const getColumnClassName = (c: DataGridColumnConfig) => {
    if (c.key === ApplicationListColumnKeys.Favorite) {
        return "hide-mobile-label text-lg-center position-absolute top-0 end-0 position-lg-static";
    }

    if (c.key === ApplicationListColumnKeys.MessagesAndTasks) {
        return "hide-mobile-label order-first order-lg-0 p-0 content-width-column";
    }

    if (c.key === ApplicationListColumnKeys.AppStatus) {
        return "content-width-column";
    }

    return undefined;
};

const getColumnRenderer = (column: DataGridColumnConfig) => {
    switch (column.key) {
        case ApplicationListColumnKeys.AppStatus:
            return StatusRenderer;
        case ApplicationListColumnKeys.MessagesAndTasks:
            return MessagesTasksRenderer;
        case ApplicationListColumnKeys.ProjectNumber:
            return ProjectNumberRenderer;
        case ApplicationListColumnKeys.DateCreated:
            return DateCreatedRenderer;
        default:
            return undefined;
    }
};

const DateCreatedRenderer = (column: DataGridColumnConfig, value: DataGridCellValue, row: DataGridRowConfig) => {
    const { data } = row;
    const date = data[column.key] ?? "";
    return <span>{formatDateAndTime(date)}</span>;
};

const StatusRenderer = (column: DataGridColumnConfig, value: DataGridCellValue, row: DataGridRowConfig) => {
    const { data } = row;

    return (
        <div className="d-flex align-items-center justify-content-between w-auto">
            <Badge className="my-auto me-3 text-wrap"> {data[column.key]}</Badge>
        </div>
    );
};

const MessagesTasksRenderer = (column: DataGridColumnConfig, value: DataGridCellValue, row: DataGridRowConfig) => {
    const { data } = row;
    const { messagesCount, tasksCount } = getMessagesAndTasksCount(data[ApplicationListColumnKeys.MessagesAndTasks] ?? "");

    return (
        <div className="d-flex flex-column gap-2 align-items-start w-auto me-3">
            {!!tasksCount && (
                <div className="d-flex gap-2 align-items-center">
                    <FontAwesomeIcon icon={["fal", "list-check"]}></FontAwesomeIcon>{" "}
                    <Badge className="rounded-circle fs-8 bg-danger text-white">{tasksCount}</Badge>
                </div>
            )}
            {!!messagesCount && (
                <div className="d-flex gap-2 align-items-center">
                    <FontAwesomeIcon icon={["fal", "envelope"]}></FontAwesomeIcon>{" "}
                    <Badge className="rounded-circle fs-8  bg-danger text-white">{messagesCount}</Badge>
                </div>
            )}
        </div>
    );
};

export const ProjectNumberRenderer = (column: DataGridColumnConfig, value: DataGridCellValue, row: DataGridRowConfig) => {
    const { data } = row;
    const isFavorite = data[ApplicationListColumnKeys.Favorite] === "1";
    const projectNumber = data[column.key] ?? "";
    return (
        <div className="d-flex  align-items-center">
            {isFavorite ? (
                <Icon className="p-2 ps-1 text-body" icon="bookmark" />
            ) : (
                <Icon className="p-2 ps-1 text-body" icon={["fal", "bookmark"]} />
            )}
            <div>{projectNumber}</div>
        </div>
    );
};

export const getMessagesAndTasksCount = (value: string) => {
    let valueLines = (value ?? "").split("\r\n");
    const messagesCount = Number(valueLines?.[0]?.split(":")[1] ?? 0);
    const tasksCount = Number(valueLines?.[1]?.split(":")[1] ?? 0);

    return { messagesCount, tasksCount };
};

export const getDateRange = (dateRange: string) => {
    let startDate = "";
    let endDate = "";

    switch (dateRange) {
        case DateRanges.LastWeek: {
            const today = new Date();
            const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            startDate = dateToJson(lastWeek)!;
            endDate = dateToJson(today)!;
            break;
        }
        case DateRanges.LastMonth: {
            const today = new Date();
            const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
            startDate = dateToJson(lastMonth)!;
            endDate = dateToJson(today)!;
            break;
        }
        default:
            startDate = "";
            endDate = "";
            break;
    }

    return { startDate, endDate };
};
