import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button";
import { isInIframe } from "components/utils/dom";
import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSWRConfig } from "swr";
import { SubmittedAppData } from "types/SubmittedAppData";
import { HeaderTextColor } from "types/SubmittedAppData";
import classNames from "classnames";
import { AppContext } from "components/App/AppContext";

export const PrintPdfButton = ({ appData, applicationNumber, programNumber, headerTextColor, className, onError }: PrintPdfButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { cache } = useSWRConfig();

    const portalConfig = useContext(AppContext);

    const onPrint = async () => {
        if (isInIframe()) {
            return;
        }

        try {
            setIsLoading(true);
            const applicationPdf = await import("./ApplicationPdf");
            await applicationPdf.downloadPdf(applicationNumber, programNumber, appData, cache, portalConfig);
        } catch (error) {
            onError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const buttonIcon = isLoading ? (
        <div className="print-button-icon d-inline-block">
            <div className="d-flex w-100 h-100">
                <Spinner className="m-auto" as="span" animation="border" size="sm" role="status" />
            </div>
        </div>
    ) : (
        <FontAwesomeIcon className="print-button-icon" icon={["fal", "print"]} />
    );

    return (
        <Button
            variant={headerTextColor === HeaderTextColor.Light ? "outline-light" : "outline-dark"}
            className={classNames("mt-auto", className)}
            disabled={isLoading}
            onClick={onPrint}
            title="Print application"
        >
            {buttonIcon}
        </Button>
    );
};

interface PrintPdfButtonProps {
    applicationNumber: string;
    programNumber: string;
    appData: SubmittedAppData;
    headerTextColor: HeaderTextColor;
    className?: string;
    onError: (error: any) => void;
}
