import { Button } from "components/Button";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { ButtonProps } from "types/ButtonProps";
import { Html } from "./Html";
import { Image, ImageProps } from "./Image";
import cn from "classnames";
import { isNil } from "lodash";

export function CardContent(props: CardContentProps) {
    const style = useWidgetStyles(props);

    let imageProps: ImageProps = {
        src: props.src,
        imageLink: props.imageLink,
        openInNewTab: props.openInNewTab,
        altText: props.altText,
    };

    const buttonProps = {
        text: props.text,
        variant: props.variant,
        buttonWidth: props.buttonWidth,
        secondaryButtonType: props.secondaryButtonType,
        href: props.href,
        target: props.target,
    };

    let customStyle;

    if (props.imageSize === "custom" && !isNil(props.customSize)) {
        customStyle = {
            maxWidth: parseFloat(props.customSize) + "%",
        };
    } else {
        imageProps = {
            ...imageProps,
            imageSize: props.imageSize,
            customSize: props.customSize,
        };
    }

    return (
        <div style={style} className={cn({ "border-0": !props.borderStyle }, { "flex-row": props.layout === "horizontal" }, "card")}>
            <div style={customStyle} className={cn({ "col-md-auto flex-shrink-1": props.layout === "horizontal" })}>
                {props.src && <Image {...imageProps} />}
            </div>
            <div className={cn({ "col-md-auto flex-shrink-1 ps-2 flex-grow-1": props.layout === "horizontal" }, "flex-column d-flex")}>
                <Html className="text-break card-text pt-3" {...props} />
                <div className="pt-3">{props.text && <Button {...buttonProps} />}</div>
            </div>
        </div>
    );
}

interface CardContentProps extends ButtonProps, ImageProps {
    content?: string;
    layout?: string;
}
