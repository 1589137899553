import React from "react";
import Form from "react-bootstrap/Form";
import { WidgetProps } from "@rjsf/core";
import { isNil, isNumber } from "lodash";
import WidgetLabel from "./WidgetLabel";
import { DateInput } from "components/Input/DateInput";

const TextWidget = (props: WidgetProps) => {
    const {
        id,
        placeholder,
        required,
        readonly,
        disabled,
        type,
        label,
        value,
        onChange,
        onBlur,
        onFocus,
        autofocus,
        options,
        schema,
        rawErrors = [],
    } = props;

    const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => onChange(value === "" ? options.emptyValue : value);
    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

    const typeValue = type || schema.type;
    const inputType = ["string"].includes(typeValue) ? "text" : ["number", "integer"].includes(typeValue) ? "number" : String(typeValue);

    const isInvalid = rawErrors && rawErrors.length > 0;

    const { defaultAriaDescribedBy } = options;

    const step = isNumber(schema.multipleOf) ? schema.multipleOf : undefined;
    let min = props.min;
    let max = props.max;

    if (inputType === "number") {
        if (isNil(min)) {
            min = schema.exclusiveMinimum ? schema.exclusiveMinimum + 1 : schema.minimum;
        }

        if (isNil(max)) {
            max = schema.exclusiveMaximum ? schema.exclusiveMaximum - 1 : schema.maximum;
        }
    }

    const InputComponent = ["date", "datetime-local", "time"].includes(inputType) ? DateInput : Form.Control;

    return (
        <>
            <WidgetLabel id={id} label={label} schema={schema} required={required} rawErrors={rawErrors} />
            <InputComponent
                id={id}
                placeholder={placeholder}
                autoFocus={autofocus}
                required={required}
                disabled={disabled}
                readOnly={readonly}
                isInvalid={isInvalid}
                aria-describedby={isInvalid ? `${id}-error` : defaultAriaDescribedBy}
                aria-invalid={isInvalid}
                list={schema.examples ? `examples_${id}` : undefined}
                type={inputType}
                value={value || value === 0 ? value : ""}
                min={min}
                max={max}
                minLength={schema.minLength}
                maxLength={schema.maxLength}
                onChange={_onChange}
                onBlur={_onBlur}
                onFocus={_onFocus}
                step={step}
            />
            {schema.examples ? (
                <datalist id={`examples_${id}`}>
                    {(schema.examples as string[]).concat(schema.default ? ([schema.default] as string[]) : []).map((example: any) => {
                        return <option key={example} value={example} />;
                    })}
                </datalist>
            ) : null}
        </>
    );
};

export default TextWidget;
