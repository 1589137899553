import { useContext } from "react";
import { ApplicationStepper } from "./ApplicationStepper";
import { ApplicationControls } from "./ApplicationControls";
import { ApplicationForm } from "./ApplicationForm";
import { AppContext } from "components/App/AppContext";
import { ApplicationPageContainer } from "./ApplicationPageContainer";
import { isNil } from "lodash";
import { getStepperColors } from "./utils";

export const PortalBuilderPreviewApplicationPage = () => {
    useContext(AppContext);
    const portalConfig = useContext(AppContext);
    const portalWizardStepList = portalConfig.requirements.portalWizardStepList;
    const activeStep = portalWizardStepList.length > 0 ? 0 : undefined;
    const configuration = {
        schema: {
            title: "Sample Form",
            type: "object",
            properties: {
                section1: {
                    type: "object",
                    title: "Section title",
                    description: "Section description",
                    properties: {
                        leftcol: {
                            type: "object",
                            title: "",
                            properties: {
                                field1: {
                                    type: "string",
                                    title: "Text Field",
                                },
                                field2: {
                                    type: "string",
                                    title: "Textarea Field",
                                },
                                field3: {
                                    type: "string",
                                    title: "Select Field",
                                    anyOf: [
                                        {
                                            title: "a",
                                            enum: ["a"],
                                        },
                                        {
                                            title: "b",
                                            enum: ["b"],
                                        },
                                        {
                                            title: "c",
                                            enum: ["c"],
                                        },
                                    ],
                                },
                                field4: {
                                    type: "string",
                                    title: "Radio Field",
                                    anyOf: [
                                        {
                                            title: "a",
                                            enum: ["a"],
                                        },
                                        {
                                            title: "b",
                                            enum: ["b"],
                                        },
                                        {
                                            title: "c",
                                            enum: ["c"],
                                        },
                                    ],
                                },
                                field5: {
                                    type: "array",
                                    title: "Checkboxes Field",
                                    uniqueItems: true,
                                    items: {
                                        type: "string",
                                        anyOf: [
                                            {
                                                title: "a",
                                                enum: ["a"],
                                            },
                                            {
                                                title: "b",
                                                enum: ["b"],
                                            },
                                            {
                                                title: "c",
                                                enum: ["c"],
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                    },
                    required: [],
                },
            },
        },
        uiSchema: {
            "ui:elementType": "page",
            "af:pageNumber": "_PAGE_NUMBER_",
            "af:defaultPage": 329,
            "af:denyLimitedAccess": "N",
            "af:status": 212,
            "af:allowEditAppForm": "Y",
            "af:runAppEntryStatusInWizard": "Y",
            "af:runAppReceivedStatusInWizard": "N",
            "af:showFormPageOnApp": "Y",
            "ui:order": ["section1"],
            section1: {
                "ui:elementType": "section",
                "af:sectionNumber": "_SECTION_NUMBER_",
                "ui:order": ["leftcol"],
                leftcol: {
                    classNames: "column",
                    "ui:elementType": "column",
                    "af:columnNumber": "_COLUMN_NUMBER_",
                    "ui:order": ["field1", "field2", "field3", "field4", "field5"],
                    field1: {
                        "ui:widget": "text",
                        "ui:elementType": "field",
                        "ui:disabled": false,
                        "af:fieldNumber": "E388BFE2B2034A51B07E3D0A16F941B4",
                        "af:status": "active",
                        "af:fieldGroup": 43,
                        "af:fieldDescriptor": "67",
                    },
                    field2: {
                        "ui:widget": "textarea",
                        "ui:elementType": "field",
                        "ui:disabled": false,
                        "af:fieldNumber": "35937E60130B11ED803D4DA99DE4421E",
                        "af:status": "active",
                        "af:fieldGroup": 43,
                        "af:fieldDescriptor": "67",
                    },
                    field3: {
                        "ui:widget": "select",
                        "ui:elementType": "field",
                        "ui:disabled": false,
                        "af:fieldNumber": "343274F63E9A475989A12AB9CAAE6CDC",
                        "af:status": "active",
                        "af:fieldGroup": 43,
                        "af:fieldDescriptor": "67",
                        "ui:separator": "-- SELECT --",
                    },
                    field4: {
                        "ui:widget": "radio",
                        "ui:elementType": "field",
                        "ui:disabled": false,
                        "af:fieldNumber": "_FIELD_RADIO_",
                        "af:status": "active",
                        "af:fieldGroup": 43,
                        "af:fieldDescriptor": "67",
                        "ui:separator": "- SELECT -",
                    },
                    field5: {
                        "ui:widget": "checkboxes",
                        "ui:elementType": "field",
                        "ui:disabled": false,
                        "af:fieldNumber": "DCD38A40B35045D794CC49D4ED6BF5C3",
                        "af:status": "active",
                        "af:fieldGroup": 43,
                        "af:fieldDescriptor": "67",
                    },
                },
            },
        },
        rules: [],
        initialValues: {},
    };

    const stepperColors = getStepperColors(portalConfig);

    return (
        <ApplicationPageContainer>
            {!isNil(activeStep) && <ApplicationStepper activeStep={activeStep} steps={portalWizardStepList} {...stepperColors} />}
            <ApplicationForm configuration={configuration} />
            <ApplicationControls
                isFirstPage
                isLastPage
                onBack={() => {}}
                onContinue={() => {}}
                onSaveDraft={() => {}}
                isProcessing={false}
                isDisqualificationPage={false}
            />
        </ApplicationPageContainer>
    );
};
