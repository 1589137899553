import useSWR, { Key, SWRResponse } from "swr";
import { isInIframe } from "components/utils/dom";
import { useState, useLayoutEffect, useRef } from "react";
import { createConfig, EMPTY_PORTAL_CONFIGURATION, getHostName } from "./utils";
import { useIframeMessaging } from "./useIframeMessaging";
import { PortalConfiguration } from "types/PortalConfiguration";
import { httpGet } from "components/utils/http";
import { PortalConfigurationResponse } from "types/PortalConfigurationResponse";

const usePortalConfiguration = (): [config: PortalConfiguration, isLoading: boolean] => {
    const isIframe = isInIframe();
    const [config, setConfig] = useState<PortalConfiguration | null>();
    const configUrl: Key = process.env.REACT_APP_PORTAL_CONFIGURATION_ENDPOINT + "?hostName=" + getHostName();
    const { data, error } = useSWR(isIframe ? null : configUrl, httpGet) as SWRResponse<PortalConfigurationResponse, any>;

    const isConfigCreated = useRef(false);

    // Set loaded config
    useLayoutEffect(() => {
        if (data) {
            try {
                const utilityTemplate = data.utilityTemplateConfiguration;
                const programTemplate = data.programTemplateConfiguration;
                const portalConfigurationRequirements = data.portalConfigurationRequirements;
                const programNumber = data.programNumber;
                const utilityName = data.utilityName;
                const programName = data.programName;
                const requirements = {
                    requireAccountNumber: portalConfigurationRequirements.requireAccountNumber === "TRUE",
                    disableUploadFileReq: portalConfigurationRequirements.disableUploadFileReq === "TRUE",
                    allowEquipmentEntry: portalConfigurationRequirements.allowEquipmentEntry === "TRUE",
                    allowEquipmentCopy: portalConfigurationRequirements.allowEquipmentCopy === "TRUE",
                    hidePortalCreateAccount: portalConfigurationRequirements.hidePortalCreateAccount === "TRUE",
                    requireEquipmentOnPortal: portalConfigurationRequirements.requireEquipmentOnPortal === "TRUE",
                    hideAcctNumberOnPortal: portalConfigurationRequirements.hideAcctNumberOnPortal === "TRUE",
                    hideDashboardPayments: portalConfigurationRequirements.hideDashboardPayments === "TRUE",
                    portalDashboardName: portalConfigurationRequirements.portalDashboardName,
                    portalWizardStepList: portalConfigurationRequirements.portalWizardStepList.split?.("|") ?? [],
                    prescreenRequiredFields: portalConfigurationRequirements.prescreenRequiredFields,
                    portalAdditionalElements: portalConfigurationRequirements.portalAdditionalElements,
                    portalContractorLabel: portalConfigurationRequirements.portalContractorLabel,
                };
                setConfig(createConfig(utilityTemplate, programTemplate, programNumber, utilityName, programName, requirements));
            } catch {
                setConfig(undefined);
            } finally {
                isConfigCreated.current = true;
            }
        }
    }, [data]);

    // Communicate with the parent window when loaded in iframe.
    useIframeMessaging(config, setConfig);

    const isLoading = !isIframe && ((!data && !error) || !isConfigCreated.current);

    return [config ?? EMPTY_PORTAL_CONFIGURATION, isLoading];
};

export default usePortalConfiguration;
