import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toggleAppFavorite } from "components/utils";
import { Badge, Breadcrumb, Card, Collapse, Table } from "react-bootstrap";
import { Heading } from "components/Heading";
import { HeadingLevel } from "types/HeadingLevel";
import { PrintPdfButton } from "../Pdf/PrintPdfButton";
import { Button } from "components/Button";
import classNames from "classnames";
import useMediaQuery from "components/utils/useMediaQuery";
import { useColor, useGradient } from "components/utils/color";
import { ContactCardForHeader } from "components/ContactCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactRequirements } from "types/ContactRequirements";
import { SubmittedAppData, HeaderTextColor } from "types/SubmittedAppData";
import { TitleText } from "components/ContactCard/TitleText";
import { SmallText } from "components/ContactCard/SmallText";
import { ApplicationTab, PageLink } from "components/App/utils";
import { SubmittedAppDataResponse } from "../useApplicationDetails";
import { PageContext } from "../..";
import { AppContext } from "components/App/AppContext";
import { AppNavBar } from "./AppNavBar";
import { TypographyFontSize } from "types/TypographyFontSize";
import { TypographyLineHeight } from "types/TypographyLineHeight";
import { formatDateAndTime } from "components/utils/date";
import { isInIframe } from "components/utils/dom";
import { RelationManagement } from "../RelatedApplications/RelationManagement";

export function ApplicationHeader({ activeTab, appData, contactRequirements, refreshAppDetails, setErrorSummary }: ApplicationHeaderProps) {
    const location = useLocation();
    const { settings, programNumber } = useContext(AppContext);

    const APP_DETAILS_MOBILE_ID = "app_details_mobile";
    const applicationNumber = new URLSearchParams(location.search).get("applicationNumber") ?? "";
    const [settingFav, setSettingFav] = useState(false);

    const [expanded, setExpanded] = useState(false);

    const [showRelationsPanel, setShowRelationsPanel] = useState(false);
    const isMobile = useMediaQuery("(max-width: 768px)");

    const premiseContact = appData?.applicationContacts?.find((c) => c.contactNumber === appData.premiseContactNumber);
    const page = useContext(PageContext);

    const headerImage = page?.leadImage;
    const headerBackground = page?.headerBackgroundColor ?? {};
    const headerTextColor = (page?.headerTextColor as HeaderTextColor) ?? HeaderTextColor.Dark;

    const firstGradient = useGradient(
        headerBackground.firstGradientColor,
        headerBackground.firstGradientValue,
        headerBackground.backgroundColorOpacity
    );
    const secondGradient = useGradient(
        headerBackground.secondGradientColor,
        headerBackground.secondGradientValue,
        headerBackground.backgroundColorOpacity
    );

    const backgroundColor = useColor(
        Boolean(headerBackground.backgroundOff) ? undefined : headerBackground.backgroundColor,
        headerBackground.backgroundColorOpacity
    );

    let background: string | undefined;

    if (!headerBackground.backgroundOff && headerBackground.backgroundOff !== undefined) {
        if (headerBackground.colorType === "linearGradient") {
            background = `linear-gradient(${headerBackground.gradientDirection}, ${firstGradient}, ${secondGradient})`;
        } else {
            background = backgroundColor;
        }
    }

    const overlayColor = useColor(headerImage?.backgroundColor, headerImage?.backgroundColorOpacity);

    const onToggleAppFavorite = async () => {
        if (isInIframe()) {
            return;
        }

        try {
            setSettingFav(true);
            await toggleAppFavorite(applicationNumber);
            await refreshAppDetails();
            setSettingFav(false);
        } catch (error) {
            setErrorSummary(error);
            setSettingFav(false);
        }
    };

    const getHeaderBackgroundColor = () => {
        const imageOverlayOff = headerImage?.backgroundOff === true || headerImage?.backgroundOff === undefined;
        const backgroundColorOff = headerBackground?.backgroundOff === true || headerBackground?.backgroundOff === undefined;
        const headerImageIsSet = headerImage?.file;

        if (imageOverlayOff && backgroundColorOff && headerImageIsSet) {
            return undefined;
        } else if (imageOverlayOff && backgroundColorOff && !headerImageIsSet) {
            return "var(--bs-gray-200)";
        } else if (imageOverlayOff && !backgroundColorOff) {
            return background;
        } else if (!imageOverlayOff && backgroundColorOff) {
            return overlayColor;
        } else {
            return background;
        }
    };

    const ActionButtons = () => (
        <>
            {appData && (
                <PrintPdfButton
                    appData={appData}
                    headerTextColor={headerTextColor}
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    className={classNames("mt-auto mb-3 print-button", {
                        "ms-3 mb-auto": !isMobile,
                        "me-3": isMobile,
                    })}
                    onError={setErrorSummary}
                />
            )}
            <Button
                onClick={onToggleAppFavorite}
                variant={headerTextColor === HeaderTextColor.Light ? "outline-light" : "outline-dark"}
                disabled={settingFav}
                className={classNames("mt-auto mb-3 me-3", {
                    "ms-3 mb-auto": !isMobile,
                })}
                title="Bookmark application"
                aria-pressed={!!appData?.applicationFlags?.isFavorite}
            >
                <FontAwesomeIcon
                    className={classNames({ "app-fav-icon": appData?.applicationFlags?.isFavorite })}
                    icon={!appData?.applicationFlags?.isFavorite ? ["fal", "bookmark"] : ["fas", "bookmark"]}
                    size={"lg"}
                />
            </Button>
            {appData?.applicationFlags?.hideRelatedApplications && (
                <Button
                    onClick={() => setShowRelationsPanel(true)}
                    aria-haspopup="dialog"
                    variant={headerTextColor === HeaderTextColor.Light ? "outline-light" : "outline-dark"}
                    className={classNames("mt-auto mb-3", {
                        "mb-auto me-3": !isMobile,
                    })}
                    title="Manage Relations"
                >
                    <FontAwesomeIcon
                        className={classNames({ "app-fav-icon": appData?.applicationFlags?.hideRelatedApplications })}
                        icon={["fal", "sitemap"]}
                        size={"lg"}
                    />
                </Button>
            )}
        </>
    );

    return (
        <div
            className={classNames("submitted-app-header-wrapper position-relative d-flex container-xxl px-0", {
                "text-light": headerTextColor === HeaderTextColor.Light,
                "text-dark": headerTextColor === HeaderTextColor.Dark,
                "white-text": headerTextColor === HeaderTextColor.Light,
                "dark-text": headerTextColor === HeaderTextColor.Dark,
            })}
            style={{ maxWidth: settings.maxWidth }}
        >
            {(headerBackground?.backgroundOff === true || headerBackground?.backgroundOff === undefined) && headerImage?.file && (
                <div className="m-auto h-100 w-100 position-absolute">
                    <img
                        className={classNames("header-image w-100 h-100", {
                            "image-cover": headerImage?.imageSize === "cover",
                            "image-auto": headerImage?.imageSize === "auto",
                        })}
                        style={{ objectPosition: `${headerImage?.alignBackgroundX} ${headerImage?.alignBackgroundY}` }}
                        src={headerImage?.file}
                        alt="Header background"
                    ></img>
                </div>
            )}
            <div className="d-flex pb-0 pt-3 w-100 h-100 z-1 mx-auto justify-content-center">
                <div className="header-content container-xxl px-md-4">
                    <Breadcrumb
                        className={classNames({
                            "text-light": headerTextColor === HeaderTextColor.Light,
                            "text-dark": headerTextColor === HeaderTextColor.Dark,
                        })}
                    >
                        <Breadcrumb.Item
                            className={classNames({
                                "text-light": headerTextColor === HeaderTextColor.Light,
                                "text-dark": headerTextColor === HeaderTextColor.Dark,
                            })}
                            linkAs={Link}
                            linkProps={{ to: PageLink.CustomerHome }}
                            active={false}
                        >
                            {isMobile ? "Back" : "My Applications"}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className={classNames({
                                "text-light": headerTextColor === HeaderTextColor.Light,
                                "text-dark": headerTextColor === HeaderTextColor.Dark,
                            })}
                            active
                        >
                            Application {appData?.projectNumber}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    {isMobile && <ActionButtons />}

                    <div
                        className={classNames("d-flex", {
                            "flex-column": isMobile,
                        })}
                    >
                        <div className="mt-md-3">
                            <div className=" d-flex w-100 justify-content-between app-top-info">
                                <div className="d-flex flex-column">
                                    <Heading
                                        level={HeadingLevel.H1}
                                        className={classNames("page-title mt-auto mt-md-0 mb-2", {
                                            "text-light": headerTextColor === HeaderTextColor.Light,
                                            "text-dark": headerTextColor === HeaderTextColor.Dark,
                                        })}
                                    >
                                        {appData?.projectName ? appData?.projectName : `Application ${appData?.projectNumber}`}
                                    </Heading>
                                    <div className=" mt-auto mt-md-0 mb-2 fs-5">{appData?.programName}</div>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <div className="d-flex justify-content-center align-items-center w-100">
                                    <div
                                        className={classNames("d-flex app-top-info-actions me-auto mb-md-5", {
                                            "w-100": isMobile,
                                            "mb-md-6":
                                                settings.fontSize === TypographyFontSize.Large &&
                                                settings.lineHeight === TypographyLineHeight.Large,
                                        })}
                                    >
                                        {isMobile && (
                                            <Card
                                                role="complementary"
                                                aria-label="Application details"
                                                className="contact-card flex-grow-1"
                                            >
                                                <Card.Body className="d-flex flex-column">
                                                    <div className="d-flex align-items-center align-self-stretch gap-2">
                                                        <div className="d-flex flex-column gap-2">
                                                            <TitleText>Status</TitleText>
                                                            <Badge className="app-badge text-wrap"> {appData?.status} </Badge>
                                                        </div>

                                                        <Button
                                                            onClick={() => setExpanded(!expanded)}
                                                            variant="outline-secondary"
                                                            size="sm"
                                                            aria-expanded={expanded}
                                                            aria-controls={APP_DETAILS_MOBILE_ID}
                                                            className={classNames("my-auto ms-auto")}
                                                            aria-label={expanded ? "Hide application details" : "Show application details"}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={["fal", expanded ? "chevron-up" : "chevron-down"]}
                                                                size={"lg"}
                                                            />
                                                        </Button>
                                                    </div>
                                                    <Collapse in={expanded}>
                                                        <div id={APP_DETAILS_MOBILE_ID}>
                                                            <div className="d-flex flex-column mt-2">
                                                                <TitleText>No.</TitleText>
                                                                <SmallText>{appData?.projectNumber}</SmallText>
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <TitleText>Created</TitleText>
                                                                <SmallText>{formatDateAndTime(appData?.applicationDate)}</SmallText>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </Card.Body>
                                            </Card>
                                        )}
                                        {!isMobile && (
                                            <>
                                                <div
                                                    role="complementary"
                                                    aria-label="Application details"
                                                    className="app-info-grid-wrapper w-100 px-3 p-2p5"
                                                >
                                                    <Table className="table-v2 mb-0">
                                                        <thead>
                                                            <tr className="fw-bold">
                                                                <th className="border-0 fs-6 p-0 pe-3">Status</th>
                                                                <th className="border-0 fs-6 p-0 pe-3">No.</th>
                                                                <th className="border-0  fs-6 p-0">Created</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="mb-0 p-0">
                                                                <td className="border-0 fw-bold p-0 pe-3" data-label="Status">
                                                                    <div className="d-flex w-100">
                                                                        <Badge className="mt-1 text-wrap"> {appData?.status} </Badge>
                                                                    </div>
                                                                </td>
                                                                <td className="border-0 p-0 pe-3 pt-1">{appData?.projectNumber}</td>
                                                                <td className="border-0 text-nowrap p-0 pt-1">
                                                                    {formatDateAndTime(appData?.applicationDate)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <ActionButtons />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={classNames("ms-md-auto mt-md-3", {
                                "mt-2": isMobile,
                                "w-100": isMobile,
                            })}
                        >
                            {!!premiseContact && <ContactCardForHeader contact={premiseContact} requirements={contactRequirements} />}
                        </div>
                    </div>
                    <AppNavBar
                        appData={appData}
                        applicationNumber={applicationNumber}
                        headerTextColor={headerTextColor}
                        activeTab={activeTab}
                    />
                </div>
            </div>
            <div
                className="header-image-overlay m-auto w-100 d-flex h-100 position-absolute top-0 start-0"
                style={{
                    background: getHeaderBackgroundColor(),
                }}
            ></div>
            {showRelationsPanel && (
                <RelationManagement
                    projectNumber={appData?.projectNumber}
                    applicationNumber={applicationNumber}
                    setShowRelationsPanel={setShowRelationsPanel}
                    onRelationsUpdated={refreshAppDetails}
                />
            )}
        </div>
    );
}

interface ApplicationHeaderProps {
    activeTab: ApplicationTab;
    setErrorSummary: (error: any) => void;
    refreshAppDetails: () => Promise<SubmittedAppDataResponse | undefined>;
    contactRequirements: ContactRequirements;
    appData?: SubmittedAppData;
}
