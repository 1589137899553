import { WidgetProps } from "@rjsf/core";
import { AppContext } from "components/App/AppContext";
import { EquipmentBlock } from "components/EquipmentBlock";
import { useEquipmentList } from "components/EquipmentBlock/useEquipmentList";
import { useContext, useEffect } from "react";

export const EquipmentBlockWidget = (props: WidgetProps) => {
    const { onChange, value } = props;
    const { applicationNumber, equipmentBlockWidgetRef, appSubmitted } = props.formContext ?? {};
    const [equipmentList = []] = useEquipmentList(applicationNumber);
    const { requirements } = useContext(AppContext);

    // Setup widget
    useEffect(() => {
        if (equipmentBlockWidgetRef) {
            equipmentBlockWidgetRef.current = {
                getSubmitValues: () => {
                    return [];
                },
                fieldNumber: props.uiSchema["af:fieldNumber"],
            };
        }

        return () => {
            if (equipmentBlockWidgetRef?.current) {
                equipmentBlockWidgetRef.current = undefined;
            }
        };
    }, [equipmentBlockWidgetRef, props.uiSchema]);

    useEffect(() => {
        if (requirements.requireEquipmentOnPortal) {
            setTimeout(() => {
                if (equipmentList.length > 0) {
                    if (value !== "true") {
                        onChange("true");
                    }
                } else if (value !== "false") {
                    onChange("false");
                }
            });
        }
    }, [equipmentList, onChange, requirements.requireEquipmentOnPortal, value]);

    if (props.readonly || appSubmitted) {
        return null;
    }

    return <EquipmentBlock applicationNumber={applicationNumber} />;
};
