import { AppContext } from "components/App/AppContext";
import { useContext } from "react";
import Color from "color";
import { isString, isNaN, isNil } from "lodash";

/**
 * Get color value from portal colors configuration.
 * colorId is returned if color is not found in configuration.
 * undefined is returned if colorId is not a color.
 */
export const useColor = (colorId?: string, colorOpacity?: string) => {
    const { colors = {} } = useContext(AppContext);
    let color = isNil(colorId) ? undefined : colors[colorId] ?? colorId;
    let opacity = 1;

    if (isString(colorOpacity)) {
        if (colorOpacity === "") {
            opacity = 0;
        } else {
            opacity = parseInt(colorOpacity, 10);
            if (!isNaN(opacity)) {
                opacity = opacity / 100;
            } else {
                opacity = 1;
            }
        }
    }

    if (isColor(color)) {
        return Color(color).alpha(opacity).string();
    }

    return undefined;
};

const isColor = (strColor?: string): boolean => {
    if (isNil(strColor)) {
        return false;
    }

    const s = new Option().style;
    s.color = strColor;
    return s.color !== "";
};

export const getColor = (colorId: string | undefined, opacity: string | undefined, colorMap: { [key: string]: string } | undefined) => {
    const color = isNil(colorId) ? undefined : colorMap?.[colorId] ?? colorId;

    if (isColor(color)) {
        return Color(color).alpha(getOpacity(opacity)).string();
    }

    return undefined;
};

export const getOpacity = (opacity: string | undefined): number => {
    let opacityValue = 1;

    if (isString(opacity)) {
        if (opacity === "") {
            opacityValue = 0;
        } else {
            opacityValue = parseInt(opacity, 10);
            if (!isNaN(opacityValue)) {
                opacityValue = opacityValue / 100;
            } else {
                opacityValue = 1;
            }
        }
    }

    return opacityValue;
};

export const useGradient = (gradientColor: string | undefined, gradientValue: number | undefined, opacity: string | undefined) => {
    let gradient: string | undefined;
    let value = "";

    if (gradientValue) {
        value = gradientValue > 0 ? gradientValue + "%" : "";
    }

    gradient = `${useColor(gradientColor, opacity)} ${value}`;

    return gradient;
};

export const adjustBackgroundContrast = (foregroundColorStr: string, backgroundColorStr: string, minContrast: number = 4.5) => {
    if (!isColor(foregroundColorStr) || !isColor(backgroundColorStr)) {
        return backgroundColorStr;
    }

    const foregroundColor = Color(foregroundColorStr);
    let backgroundColor = Color(backgroundColorStr);
    let isContrastOk = false;
    let remainingAdjustTimes = 40; // Max adjust times to prevent infinite loops

    while (!isContrastOk && remainingAdjustTimes > 0) {
        remainingAdjustTimes--;
        const contrast = foregroundColor.contrast(backgroundColor);
        isContrastOk = contrast >= minContrast;

        if (!isContrastOk) {
            if (foregroundColor.isLight()) {
                backgroundColor = backgroundColor.darken(0.1);
            } else {
                backgroundColor = backgroundColor.lighten(0.1);
            }
        }
    }

    return backgroundColor.string();
};

export const getCssColor = (variable: string) => {
    return getComputedStyle(document.documentElement).getPropertyValue(variable)?.trim();
};
