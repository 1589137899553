import { useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { Page } from "../Page";
import { NoPagesPage } from "components/Page/NoPagesPage";
import { CookiesBanner } from "components/CookiesBanner";
import usePortalConfiguration from "./usePortalConfiguration";
import { isString } from "lodash";
import { PageLink, setGlobalStyles } from "./utils";
import { Notification, ProgramNotification } from "components/Notification";
import { HeaderMenu } from "components/HeaderMenu";
import { SkipLink } from "components/SkipLink";
import ToastNotification, { ToastContext, useToastModel } from "components/Toast";
import { AppContext } from "./AppContext";
import { initGoogleAnalytics } from "components/utils/googleAnalytics";

function App() {
    const [config, isLoading] = usePortalConfiguration();
    const cookiePosition = config?.settings?.cookiesBannerPosition;
    const pages = config?.content?.pages ?? [];
    const enableNotification = config?.settings?.enableNotification;
    const enableProgramNotification = config?.settings?.enableProgramNotification;
    const googleAnalyticsTrackingId = config?.settings?.googleAnalyticsTrackingId ?? "";
    const googleTagManagerKey = config?.settings?.googleTagManagerKey ?? "";

    useLayoutEffect(() => {
        // Set attribute to not read the reCaptcha badge content by screen readers.
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");
        if (recaptchaBadge) {
            const badgeParent = recaptchaBadge.parentElement;

            /* 
            / Set attribute to parent of it is not body tag. 
            / For some reason reCaptcha badge is wrapped in additional div and has an empty iframe inside it.
            */
            if (badgeParent && badgeParent.tagName !== "BODY") {
                badgeParent.setAttribute("aria-hidden", "true");
            } else {
                recaptchaBadge.setAttribute("aria-hidden", "true");
            }
        }
        setGlobalStyles(config);
    }, [config]);

    useLayoutEffect(() => {
        if (config.settings.faviconSrc) {
            const faviconLink = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
            const faviconAppleTouchLink = document.querySelector<HTMLLinkElement>("link[rel~='apple-touch-icon']");

            if (faviconLink) {
                faviconLink.href = config.settings.faviconSrc;
            }

            if (faviconAppleTouchLink) {
                faviconAppleTouchLink.href = config.settings.faviconSrc;
            }
        }
    }, [config.settings.faviconSrc]);

    const toastModel = useToastModel();

    if (isLoading) {
        return null;
    }

    return (
        <AppContext.Provider value={config}>
            <ToastContext.Provider value={toastModel}>
                <SkipLink />
                <HeaderMenu />
                {enableNotification && <Notification />}
                {enableProgramNotification && <ProgramNotification />}
                {cookiePosition === "top" && <CookiesBanner />}
                {pages.length === 0 && <NoPagesPage />}
                <Switch>
                    {pages
                        .filter((p) => isString(p.link))
                        .map((page) => {
                            const isExactLink =
                                page.link !== "*" &&
                                !page.link.startsWith(PageLink.CustomerReset) &&
                                !page.link.startsWith(PageLink.AnonymousForm);
                            return (
                                <Route
                                    key={page.link}
                                    exact={isExactLink}
                                    path={page.link}
                                    render={(props) => {
                                        if (!initGoogleAnalytics(googleTagManagerKey, googleAnalyticsTrackingId, page)) {
                                            return null;
                                        }

                                        return <Page {...props} {...page} />;
                                    }}
                                />
                            );
                        })}
                </Switch>
                {cookiePosition === "bottom" && <CookiesBanner />}
                <ToastNotification />
            </ToastContext.Provider>
        </AppContext.Provider>
    );
}

export default App;
