import { HeaderNavigation } from "types/HeaderNavigation";
import { updateColors, updateCssVariables } from "components/utils";
import { isEmpty, isNil, parseInt, pick, unionBy } from "lodash";
import { BannerPosition } from "types/BannerPosition";
import { PortalConfiguration } from "types/PortalConfiguration";
import { UtilityTemplateConfiguration } from "types/UtilityTemplateConfiguration";
import Color from "color";
import { PortalConfigurationRequirements } from "types/PortalConfigurationRequirements";
import { getColor, getCssColor } from "components/utils/color";
import { PageConfiguration } from "types/PageConfiguration";
import { ProgramTemplateConfiguration } from "types/ProgramTemplateConfiguration";
import { BACKGROUND_PROPERTY_KEYS } from "types/BackgroundProperties";
import { PADDING_PROPERTY_KEYS } from "types/Padding";
import { MARGIN_PROPERTY_KEYS } from "types/Margin";
import { toArray } from "components/utils/array";
import { TypographyLineHeight } from "types/TypographyLineHeight";
import { TypographyFontSize } from "types/TypographyFontSize";

export const createConfig = (
    utilityTemplate: UtilityTemplateConfiguration,
    programTemplate: ProgramTemplateConfiguration,
    programNumber: string,
    utilityName: string,
    programName: string,
    requirements: PortalConfigurationRequirements
): PortalConfiguration => {
    if (isNil(utilityTemplate)) {
        return EMPTY_PORTAL_CONFIGURATION;
    }

    let pages = unionBy(programTemplate?.content?.pages, utilityTemplate?.content?.pages ?? [], "link")
        // Remove shared pages that are not at utility level and in authorized pages list any more
        .filter((p) => {
            if (
                p.isShared &&
                !PORTAL_AUTHORIZED_PAGES.some((authorizedPage) => authorizedPage.link === p.link) &&
                !(utilityTemplate?.content?.pages ?? []).some((sharedPage) => sharedPage.link === p.link)
            ) {
                return false;
            }

            return true;
        })
        // Get page config
        .map((p) => getPageConfig(p, utilityTemplate));

    pages = pages
        // Add missing authorized pages
        .concat(PORTAL_AUTHORIZED_PAGES.filter((ap) => !pages.find((p) => p.link === ap.link)))
        // Add missing public pages
        .concat(PORTAL_ANONYMOUS_PAGES.filter((ap) => !pages.find((p) => p.link === ap.link)))
        // Move pageNotFound page to the end
        .sort((a) => (a.link === "*" ? 1 : -1)) as PageConfiguration[];

    return {
        programNumber,
        requirements: {
            ...requirements,
            prescreenRequiredFields: toArray(requirements.prescreenRequiredFields).filter((field) =>
                ALLOWED_PRESCREEN_FIELDS.includes(field.requiredField)
            ),
        },
        ...utilityTemplate,
        utilityName,
        programName,
        colors: {
            ...utilityTemplate?.colors,
            ...programTemplate?.colors,
        },
        settings: {
            ...(utilityTemplate.settings ?? {}),
            ...(programTemplate?.settings ?? {}),
            siteTitleTemplate: utilityTemplate.settings?.siteTitleTemplate,
            enableNotification: utilityTemplate.settings?.enableNotification,
            notificationText: utilityTemplate.settings?.notificationText,
            notificationIcon: utilityTemplate.settings?.notificationIcon,
            notificationCloseIcon: utilityTemplate.settings?.notificationCloseIcon,
            notificationType: utilityTemplate.settings?.notificationType,
            enableProgramNotification: programTemplate?.settings?.enableProgramNotification,
            programNotificationText: programTemplate?.settings?.programNotificationText,
            programNotificationIcon: programTemplate?.settings?.programNotificationIcon,
            programNotificationCloseIcon: programTemplate?.settings?.programNotificationCloseIcon,
            programNotificationType: programTemplate?.settings?.programNotificationType,
        },
        content: {
            ...(utilityTemplate?.content ?? {}),
            pages,
        },
    };
};

/**
 * Get page configuration with not overridden properties taken from utility level config.
 */
const getPageConfig = (page: PageConfiguration, utilityTemplate: UtilityTemplateConfiguration): PageConfiguration => {
    const isStandardAuthorizedPage = PORTAL_AUTHORIZED_PAGES.some((ap) => ap.link === page.link);
    const isStandardAnonymousPage = PORTAL_ANONYMOUS_PAGES.some((ap) => ap.link === page.link);

    let utilityLevelPage = getPageConfigByLink(page.link, utilityTemplate);

    // Get predefined utility level standard page config if it is not found in utility level template.
    if (isNil(utilityLevelPage) && (isStandardAuthorizedPage || isStandardAnonymousPage)) {
        if (isStandardAuthorizedPage) {
            utilityLevelPage = PORTAL_AUTHORIZED_PAGES.find((ap) => ap.link === page.link);
        }

        if (isStandardAnonymousPage) {
            utilityLevelPage = PORTAL_ANONYMOUS_PAGES.find((ap) => ap.link === page.link);
        }
    }

    const pageAccess = isEmpty(page.pageAccess) ? utilityLevelPage?.pageAccess : page.pageAccess;

    const backgroundProperties = BACKGROUND_PROPERTY_KEYS.some((key) => !isNil(page[key]))
        ? pick(page, BACKGROUND_PROPERTY_KEYS)
        : pick(utilityLevelPage, BACKGROUND_PROPERTY_KEYS);

    const paddingProperties = PADDING_PROPERTY_KEYS.some((key) => !isNil(page[key]))
        ? pick(page, PADDING_PROPERTY_KEYS)
        : pick(utilityLevelPage, PADDING_PROPERTY_KEYS);

    const marginProperties = MARGIN_PROPERTY_KEYS.some((key) => !isNil(page[key]))
        ? pick(page, MARGIN_PROPERTY_KEYS)
        : pick(utilityLevelPage, MARGIN_PROPERTY_KEYS);

    const standardPageEditProperties =
        !isNil(page.topRowsOff) || !isNil(page.bottomRowsOff)
            ? {
                  topRowsOff: page.topRowsOff,
                  bottomRowsOff: page.bottomRowsOff,
              }
            : {
                  topRowsOff: utilityLevelPage?.topRowsOff,
                  bottomRowsOff: utilityLevelPage?.bottomRowsOff,
              };

    const pageProps = isNil(page.props) ? pick(utilityLevelPage, ["props"]) : pick(page, ["props"]);

    return {
        ...page,
        title: isEmpty(page.title) ? utilityLevelPage?.title ?? "" : page.title,
        headerNavigation: isEmpty(page.headerNavigation) ? utilityLevelPage?.headerNavigation : page.headerNavigation,
        order: isNil(page.order) ? utilityLevelPage?.order : page.order,
        openInNewTab: isNil(page.openInNewTab) ? utilityLevelPage?.openInNewTab : page.openInNewTab,
        pageAccess,
        components: isEmpty(page.components) ? utilityLevelPage?.components ?? [] : page.components ?? [],

        ...backgroundProperties,
        ...paddingProperties,
        ...marginProperties,
        ...standardPageEditProperties,
        ...pageProps,

        /* Set as a custom page only if it is not one of standard pages.
         * There are some old configs that have "/application" set as a custom page in config. Additional check fixes that issue.
         */
        custom: page.custom === true && !isStandardAuthorizedPage && !isStandardAnonymousPage,
        isAnonymous: pageAccess !== "AUTHENTICATED" && !isStandardAuthorizedPage,
    };
};

const getPageConfigByLink = (link: string, config: ProgramTemplateConfiguration | UtilityTemplateConfiguration) => {
    return (config?.content?.pages ?? []).find((p) => p.link === link);
};

export const getHostName = () => {
    const hostName = window.location.hostname;

    // For local development or local BrowserStack debugging
    if (hostName.indexOf("localhost") > -1 || hostName.indexOf("bs-local.com") > -1) {
        return "wg.portals.cloud.visiondsm.com";
    }

    return hostName;
};

export const setGlobalStyles = (config: PortalConfiguration) => {
    if (!isEmpty(config.settings.fontSize)) {
        updateCssVariables({
            "--bs-body-font-size": config.settings.fontSize,
        });
    }

    if (!isEmpty(config.settings.fontFamily)) {
        updateCssVariables({
            "--bs-body-font-family": `${config.settings.fontFamily}, sans serif`,
        });
    }

    if (!isEmpty(config.settings.textColor)) {
        const colorKey = config.settings.textColor ?? "";
        const bodyColor = config.colors[colorKey] ?? config.settings.textColor;
        updateCssVariables({
            "--bs-body-color": bodyColor,
            // @ts-ignore
            "--bs-body-color-rgb": Color(bodyColor).color,
        });
    }

    if (!isEmpty(config.settings.lineHeight)) {
        updateCssVariables({
            "--bs-body-line-height": config.settings.lineHeight,
        });
    }

    // update margin top for check control
    if (config.settings.lineHeight === TypographyLineHeight.Large) {
        updateCssVariables({
            "--check-control-margin-top": "0.5em",
        });
    } else if (config.settings.lineHeight === TypographyLineHeight.Small) {
        updateCssVariables({
            "--check-control-margin-top": "0.1em",
        });
    } else {
        updateCssVariables({
            "--check-control-margin-top": "0.25em",
        });
    }

    if (!isEmpty(config.colors.primary)) {
        const textColor = Color(config.colors.primary).isLight() ? "#333" : "#fff";
        updateCssVariables({ "--badge-color-override": textColor });
    } else {
        updateCssVariables({ "--badge-color-override": "#fff" });
    }

    setGlobalBackgroundStyles(config);

    setGlobalLinkStyles(config);

    setGlobalButtonStyles(config);

    setGlobalHeadingStyles(config);

    setGlobalListStyles(config);

    // Set global colors
    updateColors(config.colors ?? {});
};

const setGlobalBackgroundStyles = (config: PortalConfiguration) => {
    // Background for page body
    const bodyBackgroundColorId =
        config.settings.backgroundOff || isNil(config.settings.backgroundColor)
            ? getCssColor("--bs-gray-100")
            : config.settings.backgroundColor;
    const bodyBackgroundColor = getColor(bodyBackgroundColorId, config.settings.backgroundColorOpacity, config.colors);

    if (config.settings.colorType === "linearGradient" && !config.settings.backgroundOff) {
        const {
            firstGradientColor,
            firstGradientValue,
            secondGradientColor,
            secondGradientValue,
            backgroundColorOpacity,
            gradientDirection,
        } = config.settings;

        const linearGradient = `linear-gradient(${gradientDirection}, ${getColor(
            firstGradientColor,
            backgroundColorOpacity,
            config.colors
        )} ${firstGradientValue ? firstGradientValue + "%" : ""}, ${getColor(secondGradientColor, backgroundColorOpacity, config.colors)} ${
            secondGradientValue ? secondGradientValue + "%" : ""
        })`;

        updateCssVariables({
            "--bs-body-bg": linearGradient,
        });
    } else {
        updateCssVariables({
            "--bs-body-bg": bodyBackgroundColor,
        });
    }

    // Background for tables
    let textColor = new Color(config.colors[config.settings.textColor ?? ""] ?? config.settings.textColor);
    let linkColor = new Color(config.colors[config.widgets?.link?.defaultColor ?? ""] ?? config.widgets?.link?.defaultColor);
    let tableBackgroundColor = new Color(bodyBackgroundColor === "rgb(255, 255, 255)" ? "#f8f9fa" : "#fff");

    let isContrastOk = false;
    let remainingAdjustTimes = 40;
    const minContrast = 4.5;
    while (!isContrastOk && remainingAdjustTimes > 0) {
        remainingAdjustTimes--;
        const textContrast = textColor.contrast(tableBackgroundColor);
        const linkContrast = linkColor.contrast(tableBackgroundColor);
        isContrastOk = textContrast >= minContrast && linkContrast >= minContrast;

        // Adjust the color if the contrast is not ok
        if (!isContrastOk) {
            if (textColor.isLight()) {
                tableBackgroundColor = tableBackgroundColor.darken(0.1);
            } else {
                tableBackgroundColor = tableBackgroundColor.lighten(0.1);
            }
        }
    }

    updateCssVariables({
        "--table-background-color": tableBackgroundColor,
    });
};

const setGlobalLinkStyles = (config: PortalConfiguration) => {
    if (config?.widgets?.link) {
        const { defaultColor, mouseOverColor, focusColor } = config?.widgets?.link;

        if (defaultColor) {
            const defaultLinkColor = config.colors[defaultColor] ? Color(config.colors[defaultColor]).string() : defaultColor;
            updateCssVariables({
                "--link-default-color": defaultLinkColor,
            });
        }

        if (mouseOverColor) {
            const mouseoverLinkColor = config.colors[mouseOverColor] ? Color(config.colors[mouseOverColor]).string() : mouseOverColor;
            updateCssVariables({
                "--link-mouseover-color": mouseoverLinkColor,
            });
        }

        if (focusColor) {
            const defultLinkColor = config.colors[focusColor] ? Color(config.colors[focusColor]).string() : focusColor;
            updateCssVariables({
                "--link-focus-color": defultLinkColor,
            });
        }
    }
};

const setGlobalHeadingStyles = (config: PortalConfiguration) => {
    if (config?.widgets?.heading) {
        const { headingColor, headingH1Color, headingH2Color, headingH3Color, headingH4Color, headingH5Color, headingH6Color } =
            config?.widgets?.heading;

        if (headingColor && !isEmpty(headingColor)) {
            const headingColorCommon = config.colors[headingColor] ? Color(config.colors[headingColor]).string() : headingColor;
            updateCssVariables({
                "--heading-h1-color": headingColorCommon,
                "--heading-h2-color": headingColorCommon,
                "--heading-h3-color": headingColorCommon,
                "--heading-h4-color": headingColorCommon,
                "--heading-h5-color": headingColorCommon,
                "--heading-h6-color": headingColorCommon,
            });
        } else {
            if (headingH1Color) {
                const h1Color = config.colors[headingH1Color] ? Color(config.colors[headingH1Color]).string() : headingH1Color;
                updateCssVariables({
                    "--heading-h1-color": h1Color,
                });
            }

            if (headingH2Color) {
                const h2Color = config.colors[headingH2Color] ? Color(config.colors[headingH2Color]).string() : headingH2Color;
                updateCssVariables({
                    "--heading-h2-color": h2Color,
                });
            }

            if (headingH3Color) {
                const h3Color = config.colors[headingH3Color] ? Color(config.colors[headingH3Color]).string() : headingH3Color;
                updateCssVariables({
                    "--heading-h3-color": h3Color,
                });
            }

            if (headingH4Color) {
                const h4Color = config.colors[headingH4Color] ? Color(config.colors[headingH4Color]).string() : headingH4Color;
                updateCssVariables({
                    "--heading-h4-color": h4Color,
                });
            }

            if (headingH5Color) {
                const h5Color = config.colors[headingH5Color] ? Color(config.colors[headingH5Color]).string() : headingH5Color;
                updateCssVariables({
                    "--heading-h5-color": h5Color,
                });
            }

            if (headingH6Color) {
                const h6Color = config.colors[headingH6Color] ? Color(config.colors[headingH6Color]).string() : headingH6Color;
                updateCssVariables({
                    "--heading-h6-color": h6Color,
                });
            }
        }
    }
};

const setGlobalButtonStyles = (config: PortalConfiguration) => {
    if (config?.widgets?.button) {
        const {
            buttonSize,
            buttonCornerRadius,
            primaryButtonBackgroundColorDefault,
            primaryButtonBackgroundColorMouseOver,
            primaryButtonBackgroundColorFocusActive,
            primaryButtonBackgroundColorDisabled,

            ghostButtonBackgroundColorMouseOver,
            ghostButtonBackgroundColorFocusActive,

            filledButtonBackgroundColorDefault,
            filledButtonBackgroundColorMouseOver,
            filledButtonBackgroundColorFocusActive,

            primaryButtonBorderWidth,
            primaryButtonBorderColor,
            primaryButtonBorderColorOpacity,

            ghostButtonBorderWidth,
            ghostButtonBorderColorDefault,
            ghostButtonBorderColorMouseOver,
            ghostButtonBorderColorFocusActive,

            filledButtonBorderWidth,
            filledButtonBorderColorDefault,
            filledButtonBorderColorMouseOver,
            filledButtonBorderColorFocusActive,

            ghostButtonTextColorDefault,
            ghostButtonTextColorDisabled,

            shadowColor,
            shadowColorOpacity,
            shadowSize,
            shadowType,
        } = config?.widgets?.button;

        if (buttonSize === "btn-lg") {
            updateCssVariables({
                "--bs-btn-padding-y": "0.5rem",
                "--bs-btn-padding-x": "1rem",
                "--btn-font-size": "calc(var(--bs-body-font-size) * 1.25)",
            });
        }
        if (buttonSize === "btn-sm") {
            updateCssVariables({
                "--bs-btn-padding-y": "0.25rem",
                "--bs-btn-padding-x": "0.5rem",
                "--btn-font-size": "calc(var(--bs-body-font-size) * 0.875)",
            });
        }
        if (buttonSize === "btn-m") {
            updateCssVariables({
                "--bs-btn-padding-y": "0.375rem",
                "--bs-btn-padding-x": "0.75rem",
                "--btn-font-size": "var(--bs-body-font-size)",
            });
        }

        if (primaryButtonBackgroundColorDefault) {
            const defaultPrimaryButtonBackgroundColor = config.colors[primaryButtonBackgroundColorDefault]
                ? Color(config.colors[primaryButtonBackgroundColorDefault]).string()
                : primaryButtonBackgroundColorDefault;
            const textColor = Color(defaultPrimaryButtonBackgroundColor).isLight() ? "#333" : "#fff";
            updateCssVariables({
                "--btn-primary-text-color-default": textColor,
                "--btn-primary-color-default": defaultPrimaryButtonBackgroundColor,
                "--btn-primary-color-disabled":
                    Color(config.colors[primaryButtonBackgroundColorDefault]).alpha(0.5).toString() ?? primaryButtonBackgroundColorDisabled,
            });
        }

        if (primaryButtonBackgroundColorMouseOver) {
            const mouseOverColor = config.colors[primaryButtonBackgroundColorMouseOver]
                ? Color(config.colors[primaryButtonBackgroundColorMouseOver]).toString()
                : primaryButtonBackgroundColorMouseOver;

            const textColor = Color(mouseOverColor).isLight() ? "#333" : "#fff";

            updateCssVariables({
                "--btn-primary-text-color-mouseOver": textColor,
                "--btn-primary-color-mouseOver": mouseOverColor,
            });
        }

        if (primaryButtonBackgroundColorFocusActive) {
            const focusActiveColor = config.colors[primaryButtonBackgroundColorFocusActive]
                ? Color(config.colors[primaryButtonBackgroundColorFocusActive]).toString()
                : primaryButtonBackgroundColorFocusActive;

            const textColor = Color(focusActiveColor).isLight() ? "#333" : "#fff";

            updateCssVariables({
                "--btn-primary-text-color-focusActive": textColor,
                "--btn-primary-color-focusActive": focusActiveColor,
                "--btn-primary-focus-shadow-color": Color(focusActiveColor).alpha(0.25).toString(),
            });
        }

        if (primaryButtonBorderColor) {
            if (primaryButtonBorderColorOpacity) {
                updateCssVariables({
                    "--btn-primary-border-color":
                        Color(config.colors[primaryButtonBorderColor])
                            .alpha(parseInt(primaryButtonBorderColorOpacity) * 0.01)
                            .toString() ?? primaryButtonBorderColor,
                });
            } else {
                updateCssVariables({
                    "--btn-primary-border-color": Color(config.colors[primaryButtonBorderColor]).toString() ?? primaryButtonBorderColor,
                });
            }
        }

        if (filledButtonBackgroundColorDefault) {
            const defaultFilledButtonBackgroundColor = config.colors[filledButtonBackgroundColorDefault]
                ? Color(config.colors[filledButtonBackgroundColorDefault]).string()
                : filledButtonBackgroundColorDefault;

            const textColor = Color(defaultFilledButtonBackgroundColor).isLight() ? "#333" : "#fff";

            updateCssVariables({
                "--btn-filled-text-color-default": textColor,
                "--btn-filled-color-default": defaultFilledButtonBackgroundColor,
                "--btn-filled-color-disabled": Color(config.colors[defaultFilledButtonBackgroundColor]).alpha(0.5).toString(),
            });
        }

        if (filledButtonBackgroundColorMouseOver) {
            const mouseOverColor = config.colors[filledButtonBackgroundColorMouseOver]
                ? Color(config.colors[filledButtonBackgroundColorMouseOver]).toString()
                : filledButtonBackgroundColorMouseOver;

            const textColor = Color(mouseOverColor).isLight() ? "#333" : "#fff";

            updateCssVariables({
                "--btn-filled-text-color-mouseOver": textColor,
                "--btn-filled-color-mouseOver": mouseOverColor,
            });
        }

        if (filledButtonBackgroundColorFocusActive) {
            const focusActiveColor = config.colors[filledButtonBackgroundColorFocusActive]
                ? Color(config.colors[filledButtonBackgroundColorFocusActive]).toString()
                : filledButtonBackgroundColorFocusActive;

            const textColor = Color(focusActiveColor).isLight() ? "#333" : "#fff";

            updateCssVariables({
                "--btn-filled-text-color-focusActive": textColor,
                "--btn-filled-color-focusActive": focusActiveColor,
                "--btn-filled-focus-shadow-color": Color(focusActiveColor).alpha(0.25).toString(),
            });
        }

        if (filledButtonBorderColorDefault) {
            const defaultSecondaryButtonBorderColor = config.colors[filledButtonBorderColorDefault]
                ? Color(config.colors[filledButtonBorderColorDefault]).string()
                : filledButtonBorderColorDefault;

            updateCssVariables({
                "--btn-filled-border-color-default": defaultSecondaryButtonBorderColor,
                "--btn-filled-border-color-disabled": Color(config.colors[defaultSecondaryButtonBorderColor]).alpha(0.5).toString(),
            });
        }

        if (filledButtonBorderColorMouseOver) {
            const mouseOverColor = config.colors[filledButtonBorderColorMouseOver]
                ? Color(config.colors[filledButtonBorderColorMouseOver]).toString()
                : filledButtonBorderColorMouseOver;

            updateCssVariables({
                "--btn-filled-border-color-mouseOver": mouseOverColor,
            });
        }

        if (filledButtonBorderColorFocusActive) {
            const focusActiveColor = config.colors[filledButtonBorderColorFocusActive]
                ? Color(config.colors[filledButtonBorderColorFocusActive]).toString()
                : filledButtonBorderColorFocusActive;

            updateCssVariables({
                "--btn-filled-border-color-focusActive": focusActiveColor,
            });
        }

        if (ghostButtonBorderColorDefault) {
            const defaultSecondaryButtonBorderColor = config.colors[ghostButtonBorderColorDefault]
                ? Color(config.colors[ghostButtonBorderColorDefault]).string()
                : ghostButtonBorderColorDefault;

            updateCssVariables({
                "--btn-ghost-border-color-default": defaultSecondaryButtonBorderColor,
                "--btn-ghost-border-color-disabled": Color(config.colors[defaultSecondaryButtonBorderColor]).alpha(0.5).toString(),
            });
        }

        if (ghostButtonBackgroundColorMouseOver) {
            const mouseOverColor = config.colors[ghostButtonBackgroundColorMouseOver]
                ? Color(config.colors[ghostButtonBackgroundColorMouseOver]).toString()
                : ghostButtonBackgroundColorMouseOver;

            const textColor = Color(mouseOverColor).isLight() ? "#333" : "#fff";

            updateCssVariables({
                "--btn-ghost-text-color-mouseOver": textColor,
                "--btn-ghost-color-mouseOver": mouseOverColor,
            });
        }

        if (ghostButtonBackgroundColorFocusActive) {
            const focusActiveColor = config.colors[ghostButtonBackgroundColorFocusActive]
                ? Color(config.colors[ghostButtonBackgroundColorFocusActive]).toString()
                : ghostButtonBackgroundColorFocusActive;

            updateCssVariables({
                "--btn-ghost-color-focusActive": focusActiveColor,
            });
        }

        if (ghostButtonBorderColorMouseOver) {
            const mouseOverColor = config.colors[ghostButtonBorderColorMouseOver]
                ? Color(config.colors[ghostButtonBorderColorMouseOver]).toString()
                : ghostButtonBorderColorMouseOver;

            updateCssVariables({
                "--btn-ghost-border-color-mouseOver": mouseOverColor,
            });
        }

        if (ghostButtonBorderColorFocusActive) {
            const focusActiveColor = config.colors[ghostButtonBorderColorFocusActive]
                ? Color(config.colors[ghostButtonBorderColorFocusActive]).toString()
                : ghostButtonBorderColorFocusActive;

            updateCssVariables({
                "--btn-ghost-border-color-focusActive": focusActiveColor,
                "--btn-ghost-focus-shadow-color": Color(focusActiveColor).alpha(0.25).toString(),
            });
        }

        if (ghostButtonTextColorDefault) {
            const defaultGhostButtonTextColorDefault = config.colors[ghostButtonTextColorDefault]
                ? Color(config.colors[ghostButtonTextColorDefault]).string()
                : ghostButtonTextColorDefault;

            updateCssVariables({
                "--btn-ghost-text-color-default": defaultGhostButtonTextColorDefault,
                "--btn-ghost-text-color-disabled":
                    Color(config.colors[ghostButtonTextColorDefault]).alpha(0.5).toString() ?? ghostButtonTextColorDisabled,
            });
        }

        if (shadowColor) {
            let buttonShadowColor = config.colors[shadowColor] ? Color(config.colors[shadowColor]).string() : shadowColor;

            if (shadowColorOpacity) {
                buttonShadowColor =
                    Color(config.colors[shadowColor])
                        .alpha(parseInt(shadowColorOpacity) * 0.01)
                        .toString() ?? shadowColor;
            }

            if (shadowType) {
                updateCssVariables({
                    "--btn-shadow": `${shadowType} ${shadowSize} ${buttonShadowColor}`,
                });
            } else {
                updateCssVariables({
                    "--btn-shadow": `${shadowSize} ${buttonShadowColor}`,
                });
            }
        } else {
            updateCssVariables({
                "--btn-shadow": "none",
            });
        }

        updateCssVariables({
            "--btn-border-radius": buttonCornerRadius,
            "--btn-primary-border-width": primaryButtonBorderWidth,
            "--btn-filled-border-width": filledButtonBorderWidth,
            "--btn-ghost-border-width": ghostButtonBorderWidth,
        });
    }
};

const setGlobalListStyles = (config: PortalConfiguration) => {
    if (config?.widgets?.list) {
        const { margin } = config.widgets.list;

        if (margin) {
            updateCssVariables({
                "--list-margin-bottom": margin,
            });
        }
    }
};

const ALLOWED_PRESCREEN_FIELDS = ["acct_number", "address", "addressnosuffix", "meterid", "phone", "zip"];

export enum PageLink {
    Home = "/",
    CustomerHome = "/customer-home",
    Contacts = "/contacts",
    AccountDetails = "/account-details",
    AccountCreate = "/create-account",
    Application = "/application",
    ApplicationSubmitted = "/application-submitted",
    ApplicationView = "/submitted-application",
    SignIn = "/sign-in",
    SignOut = "/sign-out",
    ChangePassword = "/change-password",
    ChangeEmail = "/change-email",
    CustomerReset = "/customerReset",
    ForgotPassword = "/forgot-password",
    ContactUs = "/contact-us",
    Cookies = "/cookies",
    ContractorSearch = "/contractor-search",
    CustomerAuthCode = "/customerAuthCode",
    customerEmailAuthCode = "/customerEmailAuthCode",
    AnonymousForm = "/extform",
    Prescreen = "/prescreen",
    AnonymousThankYouPage = "/extform-submitted",
    All = "*",
    NoPages = "/no-pages",
}

export enum ApplicationTab {
    Tasks = "tasks",
    Contacts = "contacts",
    Inbox = "inbox",
    Equipment = "equipment",
    Forms = "forms",
    Payments = "payments",
    Files = "files",
    Correspondence = "correspondence",
    Report = "report",
    RelatedApplications = "relatedApplications",
}

export const EMPTY_PORTAL_CONFIGURATION: PortalConfiguration = {
    programNumber: "",
    utilityName: undefined,
    programName: undefined,
    requirements: {
        requireAccountNumber: false,
        allowEquipmentEntry: false,
        allowEquipmentCopy: false,
        hidePortalCreateAccount: false,
        requireEquipmentOnPortal: false,
        disableUploadFileReq: false,
        hideAcctNumberOnPortal: false,
        portalDashboardName: "",
        portalWizardStepList: [],
        hideDashboardPayments: false,
    },
    settings: {
        cookiesBannerPosition: "bottom" as BannerPosition,
        enableCookiesBanner: false,
        siteTitleTemplate: "{utilityName}: {programName} - {pageName}",
        fontSize: TypographyFontSize.Small,
        lineHeight: TypographyLineHeight.Small,
        enableGoogleAnalytics: false,
        googleAnalyticsTrackingId: undefined,
        googleTagManagerKey: undefined,
        enableNotification: false,
        notificationText: "",
        notificationIcon: false,
        notificationCloseIcon: false,
        notificationType: "info",
        enableProgramNotification: false,
        programNotificationText: "",
        programNotificationIcon: false,
        programNotificationCloseIcon: false,
        programNotificationType: "info",
    },
    colors: {},
    header: {
        isEnabled: false,
    },
    footer: {
        isEnabled: false,
    },
    content: {
        pages: [],
    },
    widgets: {
        button: {},
        contentWrapper: {},
        link: {},
        heading: {},
        list: {},
    },
};

export const PORTAL_ANONYMOUS_PAGES: PageConfiguration[] = [
    {
        title: "Anonymous form",
        link: PageLink.AnonymousForm,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "AnonymousApplicationPage",
            },
        ],
        isAnonymous: true,
    },
    {
        title: "Anonymous Thank You",
        link: PageLink.AnonymousThankYouPage,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "AnonymousThankYouPage",
            },
        ],
        isAnonymous: true,
    },
];

export const PORTAL_AUTHORIZED_PAGES: PageConfiguration[] = [
    {
        title: "Dashboard",
        link: PageLink.CustomerHome,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "CustomerDashboardPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "Contacts",
        link: PageLink.Contacts,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "ContactsPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "Account details",
        link: PageLink.AccountDetails,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "AccountDetailsPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "Application",
        link: PageLink.Application,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "ApplicationPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "View Application",
        link: PageLink.ApplicationView,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "SubmittedApplicationPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "Application Submitted",
        link: PageLink.ApplicationSubmitted,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "ThankYouPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "SIGN OUT",
        link: PageLink.SignOut,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "SignOutPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "Change password",
        link: PageLink.ChangePassword,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "ChangePasswordPage",
            },
        ],
        isAnonymous: false,
    },
    {
        title: "Change email",
        link: PageLink.ChangeEmail,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "ChangeEmailPage",
            },
        ],
        isAnonymous: true,
    },
    {
        title: "Prescreen",
        link: PageLink.Prescreen,
        headerNavigation: HeaderNavigation.NEVER,
        components: [
            {
                type: "PrescreenPage",
            },
        ],
        isAnonymous: false,
    },
];
