import { ApplicationContact } from "components/utils/contacts";

export interface SubmittedAppData {
    primaryContactNumber: string;
    premiseContactNumber: string;
    contractorContactNumber: string;
    status: string;
    applicationDate: string;
    projectNumber: string;
    projectName: string;
    progId: string;
    programName: string;
    applicationFlags: {
        isApplicationLocked: boolean;
        isEditablePrimaryContact: boolean;
        isEditablePremiseContact: boolean;
        isEditableContractorContact: boolean;
        isEditableEquipment: boolean;
        allowApprovedMeasureShortSearch: boolean;
        isFavorite?: boolean;
        hidePayments?: boolean;
        hideRelatedApplications?: boolean;
    };
    applicationContacts: ApplicationContact[];
    applicationForms: ApplicationForm[];
    applicationEquipment: ApplicationEquipment[];
    applicationRebates: ApplicationRebate[];
    applicationNotes: ApplicationNote[];
    applicationFiles: ApplicationFile[];
}
export interface ApplicationForm {
    pageNumber: string;
    pageName: string;
    allowFormEdit: boolean;
}
export interface ApplicationFile {
    fileId: string;
    fileName: string;
    uploadDate: string;
    fileSize: string;
}

export interface ApplicationNote {
    noteDate: string;
    userName: string;
    note: string;
    noteType: string;
    refType: string;
    isUnread?: boolean;
}

export interface ApplicationRebate {
    rebateNumber: string;
    rebateContactNumber: string;
    rebateType: string;
    amount: string;
    payee: string;
    dateEntered: string;
    checkNumber: string;
    checkDate: string;
    checkResolutionStatus: string;
    checkResolutionStatusDate: string;
    checkAmount: string;
}

export interface ApplicationEquipment {
    equipId: string;
    catalogName: string;
    quantity: string;
    catalogCategory: string;
    catalogType: string;
    equipmentAttributes: [
        {
            attributeName: string;
            attributeValue: string;
        }
    ];
}

export enum HeaderTextColor {
    Light = "light",
    Dark = "dark",
}
