import { ErrorSummary } from "components/ErrorSummary";
import { Offcanvas, Spinner } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { RelationManagementGrid } from "./RelationManagementGrid";
import { GridResponse, SearchParams } from "../types";
import useSWR, { KeyedMutator } from "swr";
import { FilterPair } from "components/Page/DashboardPageV2/types";
import { isEmpty, omit, toArray } from "lodash";
import { getUrl, httpPostAuthorized } from "components/utils/http";
import { isInIframe } from "components/utils/dom";
import { useGridConfig } from "components/utils/useGridConfig";
import { getBrowserTimezoneOffset } from "components/utils/date";
import { ErrorSummaryInterface } from "types/ErrorSummary";
import { DataGridPaging } from "components/DataGrid/DataGridPaging";
import { isNullOrWhitespace } from "components/utils/validation";
import { SubmittedAppDataResponse } from "../../useApplicationDetails";
import cn from "classnames";
import useMediaQuery from "components/utils/useMediaQuery";

const RELATIONS_MANAGEMENT_GRID_ID = "B8738E35-F26E-4A21-804A-813217E30FF7";

export const RelationManagement = ({
    projectNumber,
    applicationNumber,
    setShowRelationsPanel,
    onRelationsUpdated,
}: {
    projectNumber?: string;
    applicationNumber: string;
    setShowRelationsPanel: React.Dispatch<React.SetStateAction<boolean>>;
    onRelationsUpdated: (() => void) | (() => Promise<SubmittedAppDataResponse | undefined>);
}) => {
    const timeOffset = getBrowserTimezoneOffset();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string>("");
    const [sortAsc, setSortAsc] = useState<boolean>(true);
    const [filterPairs, setFilterPairs] = useState<FilterPair[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [errorSummary, setErrorSummary] = useState<ErrorSummaryInterface>();
    const [gridConfig, isLoadingGridConfig] = useGridConfig(RELATIONS_MANAGEMENT_GRID_ID);
    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
    const isMobile = useMediaQuery("(max-width: 768px)");

    const onFilterChange = useCallback(
        (newValue: string) => {
            if (newValue !== filter) {
                setFilter(newValue);
                if (!isEmpty(newValue)) {
                    const filterPairs = newValue.split("|").map((f) => {
                        const filterKey = f.split("=")[0];
                        const filterValue = f.split("=")[1];
                        return {
                            field: filterKey,
                            value: filterValue,
                            title: filterKey,
                        };
                    });
                    setFilterPairs(filterPairs);
                } else {
                    setFilter("");
                    setFilterPairs([]);
                }
                setPageNumber(1);
            }
        },
        [filter]
    );

    const onSortChange = (key: string) => {
        if (!isEmpty(sortBy)) {
            if (sortBy === key) {
                setSortAsc((prev) => !prev);
                if (sortAsc) {
                    setSortAsc(false);
                } else {
                    setSortBy("");
                    setSortAsc(true);
                }
            } else {
                setSortBy(key);
                setSortAsc(true);
            }
        } else {
            setSortBy(key);
            setSortAsc(true);
        }
    };
    const onPageChange = (pageNumber: number) => {
        setPageNumber(pageNumber);
        setSelectedRows(new Set());
    };

    const [gridResponse, isLoadingApplications, error, refreshList] = useRelationManagementList(
        applicationNumber,
        filterPairs,
        setErrorSummary,
        sortBy,
        sortAsc,
        timeOffset,
        pageNumber,
        pageSize
    );

    const onRelateApplication = useCallback(() => {
        setSelectedRows(new Set());
        onRelationsUpdated();
        refreshList();
    }, [onRelationsUpdated, refreshList]);

    const applications = gridResponse?.items;
    const pagesCount = Math.ceil((gridResponse?.totalRecords ?? 0) / pageSize);
    const totalRecords = gridResponse?.totalRecords;

    return (
        <Offcanvas
            className={cn("relations-panel", { "offcanvas-75": !isMobile })}
            show
            placement="end"
            backdrop="false"
            aria-labelledby="manage-relations-title"
            onHide={() => setShowRelationsPanel(false)}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="edit-equipment-title">Manage Relations</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {isLoadingApplications && isLoadingGridConfig && !error ? (
                    <Spinner className="align-self-center" animation="border" role="status">
                        <span className="visually-hidden">Loading relations...</span>
                    </Spinner>
                ) : (
                    <>
                        <ErrorSummary className="mt-3" errorSummary={errorSummary} />
                        <RelationManagementGrid
                            projectNumber={projectNumber}
                            gridConfig={gridConfig}
                            items={applications}
                            sortBy={sortBy}
                            sortAsc={sortAsc}
                            onSortChange={onSortChange}
                            onFilterChange={onFilterChange}
                            filter={filter}
                            isLoadingGrid={isLoadingGridConfig}
                            applicationNumber={applicationNumber}
                            onRelationsUpdated={onRelateApplication}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                        />
                        <DataGridPaging
                            pageNumber={pageNumber}
                            pagesCount={pagesCount}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                            onPageSizeChange={setPageSize}
                        />
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

const useRelationManagementList = (
    applicationNumber: string,
    filterPairs: FilterPair[],
    setErrorSummary: any,
    sortBy: string = "",
    sortAsc: boolean = true,
    timeOffset: number = 0,
    pageNumber: number = 1,
    pageSize: number = 10
): [gridResponse: GridResponse | undefined, isLoading: boolean, error: any, refresh: KeyedMutator<any>] => {
    const [response, setResponse] = useState<any>();
    const baseUrl =
        applicationNumber && pageSize > 0
            ? getUrl(process.env.REACT_APP_APPLICATION_RELATION_MANAGEMENT_ENDPOINT, { applicationNumber })
            : null;

    let url: string | null = null;
    if (baseUrl) {
        url = baseUrl + "?" + getQueryParams(pageNumber, pageSize, timeOffset).toString();
    }

    const body = useMemo(
        () => getSearchParams(filterPairs, sortBy, sortAsc, applicationNumber),
        [filterPairs, sortBy, sortAsc, applicationNumber]
    );
    const key = useMemo(() => (isInIframe() ? null : [url, body]), [url, body]);

    const { data, error, mutate } = useSWR(key, () => httpPostAuthorized(url!, body));

    if (error) {
        setErrorSummary(error);
    }

    const isLoading = !error && !data && url !== null;

    // Update data only when not loading anymore. Prevents grid flicker.
    if (!isLoading && response !== data) {
        setResponse(data);
    }

    const result = useMemo(() => {
        let result: GridResponse | undefined = undefined;
        if (!isEmpty(response?.grid?.rows)) {
            const rows = toArray(response?.grid.rows.length > 1 ? response.grid.rows : [response.grid.rows]);

            if (rows?.length > 0) {
                const items = rows.map((i: any) => omit(i, ["MoreRecords", "totRecords"]));
                const totalRecords = Number(rows[0]["totRecords"]);
                const remainingRecords = Number(rows[0]["MoreRecords"]);

                result = {
                    items,
                    totalRecords,
                    remainingRecords,
                };
            }
        }

        return result;
    }, [response]);

    return [result, isLoading, error, mutate];
};

const getQueryParams = (pageNumber: number, pageSize: number, timeOffset: number) => {
    const query = new URLSearchParams();
    query.append("pageNum", String(pageNumber));
    query.append("recsPerPage", String(pageSize));
    query.append("timeOffset", String(timeOffset));

    return query;
};

const getSearchParams = (filterPairs: FilterPair[], sortBy: string, sortAsc: boolean, relSourceAppId: string) => {
    const searchFilter: typeof searchParams.search.searchFilter = [
        {
            fields: {
                searchField: [],
            },
            searchValue: [],
            searchMinValue: "",
            searchMaxValue: "",
        },
    ];

    for (const pair of filterPairs) {
        const searchField = [pair.field];

        let searchValue: string[] = [];
        let searchMinValue = "";
        let searchMaxValue = "";

        searchValue.push(addWildcards(pair.value));

        // Search value cannot be empty.
        if (isEmpty(searchValue)) {
            searchValue = [relSourceAppId];
        }

        if (searchField) {
            searchFilter.push({
                fields: {
                    searchField,
                },
                searchValue,
                searchMinValue,
                searchMaxValue,
            });
        }
    }

    const searchSort = [];

    if (!isEmpty(sortBy)) {
        searchSort.push({
            sortField: sortBy,
            sortAsc: sortAsc ? "1" : "0",
            sortOrder: "1",
        });
    }

    const searchParams: SearchParams = {
        search: {
            searchFilter,
        },
        sort: {
            searchSort,
        },
    };

    return searchParams;
};

const addWildcards = (value: string) => {
    if (isNullOrWhitespace(value)) {
        return value;
    }

    return `*${value.replace(/^\*|\*$/g, "")}*`;
};
