import cn from "classnames";
import { Button } from "components/Button";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ErrorSummary } from "components/ErrorSummary";
import { submitByRefPromise } from "components/JsonForm/utils";
import Form from "@rjsf/core";
import { ListGroup, Offcanvas, Spinner, Stack } from "react-bootstrap";
import { Icon } from "components/Icon";
import { ApprovedEquipmentDetailsModel, ApprovedEquipmentSearchFilter, EquipmentCatalog, EquipmentDetailsFromCatalog } from "./types";
import { ApprovedEquipmentSearchResult, useApprovedEquipmentSearch } from "./useApprovedEquipmentSearch";
import { useApprovedMeasureGridConfig } from "./useApprovedMeasureGridConfig";
import { createApprovedEquipment, createEquipment, getApprovedEquipmentRowId, getAttributeTitle, updateEquipment } from "./utils";
import { useApprovedEquipmentDetails } from "./useApprovedEquipmentDetails";
import { OffcanvasContext } from "./EquipmentAdd";
import { SubmitButton } from "components/Button/SubmitButton";
import { EquipmentBlockContext } from "components/utils/contexts";
import { Equipment } from "./Equipment";
import { EquipmentEditSubmitItem } from "types/EquipmentEditSubmitItem";
import { isEmpty, omit } from "lodash";
import { useEquipmentValidationTypes } from "components/utils/useEquipmentValidationTypes";
import { useTrueFalseTypes } from "components/utils/useTrueFalseTypes";
import { createId } from "components/utils/string";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";
import { useToast } from "components/Toast";
import { EquipmentLabel } from "components/Page/SubmittedApplicationV2/ApplicationEquipment/EquipmentLabel";
import { ApprovedEquipmentGrid } from "./ApprovedEquipmentGrid";
import { DataGridPaging } from "components/DataGrid/DataGridPaging";
import useMediaQuery from "components/utils/useMediaQuery";

import "./ApprovedEquipmentSearchResults.scss";

export const ApprovedEquipmentSearchResults = ({
    searchFilter,
    equipmentDetails,
    catalogNumber,
    onClose,
    activeCatalog,
    initialItemsFound,
    setInitialItemsFound,
}: ApprovedEquipmentSearchResultsProps) => {
    const { setIsWidePanel } = useContext(OffcanvasContext);
    const { applicationNumber, onEquipmentAddClose, onEquipmentAdded } = useContext(EquipmentBlockContext);
    const formRef = useRef<Form<any>>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showEquipmentDetails, setShowEquipmentDetails] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const [liveValidate, setLiveValidate] = useState<boolean>(false);
    const [manualEntry, setManualEntry] = useState<boolean>(false);
    const toast = useToast();

    const [selectedEquipment, setSelectedEquipment] = useState<ApprovedEquipmentSearchResult>();

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [filter, setFilter] = useState<string>("");
    const [sortAsc, setSortAsc] = useState<boolean>(true);
    const [sortBy, setSortBy] = useState<string>("");
    const [showSearchGrid, setShowSearchGrid] = useState<boolean>(false);

    const [searchResults, isLoadingSearchResults, searchError] = useApprovedEquipmentSearch(
        applicationNumber,
        searchFilter,
        pageNumber,
        pageSize,
        filter,
        sortBy,
        sortAsc
    );

    const totalRecords = searchResults?.totalRecords ?? 0;
    const pagesCount = Math.ceil(totalRecords / pageSize);
    const equipmentList = searchResults?.items ?? [];

    const [gridConfig, isLoadingGridConfig] = useApprovedMeasureGridConfig(applicationNumber, searchFilter.industryMeasureNumber);
    const rowId = getApprovedEquipmentRowId(selectedEquipment, gridConfig);

    const [approvedEquipment, isLoadingApprovedEquipment, approvedEquipmentError] = useApprovedEquipmentDetails(
        searchFilter.industryMeasureNumber,
        rowId
    );

    const onSortChange = (key: string) => {
        if (!isEmpty(sortBy)) {
            if (sortBy === key) {
                setSortAsc((prev) => !prev);
                if (sortAsc) {
                    setSortAsc(false);
                } else {
                    setSortBy("");
                    setSortAsc(true);
                }
            } else {
                setSortBy(key);
                setSortAsc(true);
            }
        } else {
            setSortBy(key);
            setSortAsc(true);
        }
    };

    // Preload equipment details
    useEquipmentValidationTypes();
    useTrueFalseTypes();

    useEffect(() => {
        if (totalRecords === 1 && searchResults?.items?.length === 1) {
            setSelectedEquipment(searchResults.items[0]);
        }
    }, [searchResults?.items, totalRecords]);

    useEffect(() => {
        if ((approvedEquipment || initialItemsFound) && !showEquipmentDetails) {
            setIsWidePanel(true);
        }

        return () => {
            setIsWidePanel(false);
        };
    }, [setIsWidePanel, approvedEquipment, initialItemsFound, showEquipmentDetails]);

    useEffect(() => {
        setErrorSummary(searchError ?? approvedEquipmentError);
    }, [searchError, approvedEquipmentError]);

    const onSelect = (equipment: ApprovedEquipmentSearchResult) => {
        setSelectedEquipment(equipment);
        setShowSearchGrid(false);
    };

    const onFilterChange = (newValue: string) => {
        if (newValue !== filter) {
            setFilter(newValue);
            setPageNumber(1);
        }
    };

    const onAcceptApprovedEquipment = () => {
        setShowEquipmentDetails(true);
        setIsWidePanel(false);
    };

    const onAdd = async () => {
        try {
            setIsSubmitting(true);
            setLiveValidate(true);
            const formData = await submitByRefPromise(formRef);

            if (applicationNumber && rowId) {
                const quantity = formData.quantity;
                const response = await createApprovedEquipment(applicationNumber, searchFilter.industryMeasureNumber, rowId, quantity);

                if (response) {
                    const equipmentNumber = response.equipid;
                    const equipmentItem: EquipmentEditSubmitItem = {
                        catalogNumber,
                        quantity,
                        attributes: Object.keys(omit(formData, "quantity")).map((key: string) => ({
                            attributename: key,
                            attributevalue: String(formData[key] ?? ""),
                        })),
                    };

                    await updateEquipment(applicationNumber, equipmentNumber, equipmentItem);
                    toast.success(response.responseMessage);
                }

                onEquipmentAdded && onEquipmentAdded();
                onEquipmentAddClose && onEquipmentAddClose();
            }
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
            setTimeout(() => {
                setLiveValidate(false);
            });
        }
    };

    const onBack = () => {
        setShowSearchGrid(true);
    };

    const onManualEntryAdd = async () => {
        try {
            const formData = await submitByRefPromise(formRef);

            if (applicationNumber && catalogNumber) {
                const equipmentItem: EquipmentAddSubmitItem = {
                    equipID: createId(),
                    catalogNumber,
                    quantity: formData.quantity,
                    attributes: Object.keys(omit(formData, "quantity")).map((key: string) => ({
                        attributename: key,
                        attributevalue: String(formData[key] ?? ""),
                    })),
                };

                setIsSubmitting(true);
                const response = await createEquipment(applicationNumber, equipmentItem);
                toast.success(response.responseMessage);
            }

            onEquipmentAdded && onEquipmentAdded();
            onEquipmentAddClose && onEquipmentAddClose();
            setManualEntry(false);
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    const onManualEntry = () => {
        setManualEntry(true);
        setShowEquipmentDetails(true);
    };

    if ((isLoadingSearchResults && !searchResults) || isLoadingApprovedEquipment || isLoadingGridConfig) {
        return (
            <Panel>
                <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                    <span className="visually-hidden">Searching approved equipment...</span>
                </Spinner>
                <PanelControls onCancel={onClose} />
            </Panel>
        );
    }

    if (showEquipmentDetails) {
        // fill attribute values with data from approved equipment search
        const equipmentDetailsFromCatalog: EquipmentDetailsFromCatalog = {
            ...equipmentDetails,
            attributes: equipmentDetails.attributes.map((attribute) => ({
                ...attribute,
                value:
                    approvedEquipment?.attributes?.find(
                        (approvedEquipmentAttribute) =>
                            (approvedEquipmentAttribute.attributeName ?? approvedEquipmentAttribute.attribute ?? "").toLowerCase() ===
                            (attribute.attributeName ?? "").toLowerCase()
                    )?.val ??
                    attribute.value ??
                    attribute.defaultValue,
            })),
        };
        return (
            <Panel>
                {activeCatalog && (
                    <>
                        <EquipmentLabel equipment={activeCatalog} />
                        <hr />
                    </>
                )}
                <ErrorSummary errorSummary={errorSummary} />
                <Equipment formRef={formRef} item={equipmentDetailsFromCatalog} liveValidate={liveValidate} />
                <PanelControls isSubmitting={isSubmitting} onCancel={onClose} onSubmit={manualEntry ? onManualEntryAdd : onAdd} />
            </Panel>
        );
    }

    if (approvedEquipment && !showSearchGrid) {
        return (
            <Panel>
                <ErrorSummary errorSummary={errorSummary} />
                <p>We've located the equipment that we think matches the item you are attempting to add.</p>
                <p>
                    Please review the information displayed below to verify that it is the correct item you would like to add. If the
                    information is correct, press the button below to continue.
                </p>
                <ApprovedEquipmentDetails approvedEquipment={approvedEquipment} />
                <PanelControls
                    isSubmitting={isSubmitting}
                    submitButtonText="Continue"
                    onCancel={onClose}
                    onBack={onBack}
                    onSubmit={onAcceptApprovedEquipment}
                />
            </Panel>
        );
    }

    if (!initialItemsFound && totalRecords === 0) {
        return (
            <Panel>
                <ErrorSummary errorSummary={errorSummary} />
                <p>
                    We could not locate any equipment that matches the item you are attempting to add. Click <b>Start Over</b> to try
                    searching again.
                </p>
                <p>
                    To manually enter the equipment information instead, click <b>Manual Entry</b>.
                </p>
                <PanelControls onCancel={onClose} onManualEntry={onManualEntry} />
            </Panel>
        );
    }

    if (initialItemsFound || (totalRecords > 1 && equipmentList.length > 1) || showSearchGrid) {
        return (
            <Panel className="py-0 px-2">
                <div className="px-2">
                    <ErrorSummary errorSummary={errorSummary} />
                    <p>We’ve located potential matches.</p>
                    <p>
                        You can further refine the results by filtering the columns. Using an * as a wildcard in your filter value can help
                        locate potential matches. For example: ABC123*
                    </p>
                </div>
                <ApprovedEquipmentGrid
                    items={equipmentList}
                    gridConfig={gridConfig}
                    onSelect={onSelect}
                    sortBy={sortBy}
                    sortAsc={sortAsc}
                    onSortChange={onSortChange}
                    onFilterChange={onFilterChange}
                    filter={filter}
                    isLoadingGrid={isLoadingSearchResults}
                    setInitialItemsFound={setInitialItemsFound}
                />
                <DataGridPaging
                    pageNumber={pageNumber}
                    pagesCount={pagesCount}
                    pageSize={pageSize}
                    totalRecords={totalRecords}
                    onPageChange={setPageNumber}
                    onPageSizeChange={setPageSize}
                />
                <PanelControls className="p-3" onCancel={onClose} />
            </Panel>
        );
    }

    return null;
};

interface ApprovedEquipmentSearchResultsProps {
    searchFilter: ApprovedEquipmentSearchFilter;
    equipmentDetails: EquipmentDetailsFromCatalog;
    catalogNumber: string;
    onClose: () => void;
    activeCatalog?: EquipmentCatalog;
    initialItemsFound: boolean;
    setInitialItemsFound: React.Dispatch<React.SetStateAction<boolean>>;
}

const Panel = ({ className, children }: PanelProps) => {
    return <Offcanvas.Body className={cn("approved-equipment-search-results d-flex flex-column", className)}>{children}</Offcanvas.Body>;
};

interface PanelProps {
    className?: string;
    /** Panel content */
    children: React.ReactNode;
}

const PanelControls = ({
    className,
    isSubmitting,
    submitButtonText = "Submit",
    onSubmit,
    onCancel,
    onBack,
    onManualEntry,
    manualEntryButtonText = "Manual Entry",
}: PanelControlsProps) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    return (
        <Stack className={cn("equipment-controls flex-shrink-0 mt-auto", className)} direction="horizontal" gap={isMobile ? 1 : 3}>
            <Button variant="secondary" className="text-nowrap me-auto" onClick={onCancel} disabled={isSubmitting}>
                <Icon icon="arrow-left" className="me-1" />
                Start Over
            </Button>
            {onBack && (
                <Button variant="secondary" onClick={onBack} disabled={isSubmitting}>
                    Back
                </Button>
            )}
            {onSubmit && (
                <SubmitButton isSubmitting={isSubmitting} spinnerText="Adding item to application..." onClick={onSubmit}>
                    {submitButtonText}
                </SubmitButton>
            )}
            {onManualEntry && (
                <SubmitButton className="ms-auto" onClick={onManualEntry} spinnerText="Adding item to application...">
                    {manualEntryButtonText}
                </SubmitButton>
            )}
        </Stack>
    );
};

interface PanelControlsProps {
    className?: string;
    isSubmitting?: boolean;
    submitButtonText?: string;
    manualEntryButtonText?: string;
    onSubmit?: () => void;
    onCancel: () => void;
    onBack?: () => void;
    onManualEntry?: () => void;
}

const ApprovedEquipmentDetails = ({ approvedEquipment }: ApprovedEquipmentDetailsProps) => {
    return (
        <ListGroup className="approved-equipment-details-list mb-2 flex-shrink-0">
            {approvedEquipment.attributes.map((item, index) => {
                const id = `${approvedEquipment.industryMeasureNumber}-${approvedEquipment.rowId}-${index}`;
                return (
                    <ListGroup.Item key={index} className="d-flex justify-content-between align-items-start">
                        <div id={id} className="fw-bold me-auto text-break">
                            {getAttributeTitle(undefined, item.attribute)}
                        </div>
                        <div className="attribute-value text-break" aria-labelledby={id}>
                            {item.val}
                        </div>
                    </ListGroup.Item>
                );
            })}
        </ListGroup>
    );
};

interface ApprovedEquipmentDetailsProps {
    approvedEquipment: ApprovedEquipmentDetailsModel;
}
