import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { Button } from "components/Button";
import { DataGridColumnConfig } from "components/DataGrid/types";
import { capitalize, isEmpty } from "lodash";
import { getAriaSortAttribute } from "./utils";

export const DataGridHeader = (props: DataGridHeaderProps) => {
    const { columns, hasActionsColumn = true, onSortChange, stickyActionsColumn, canSelectRows } = props;

    if (isEmpty(columns)) {
        return null;
    }

    return (
        <tr className="data-grid-header">
            {canSelectRows && <th className="py-2 bg-white"></th>}
            {columns.map((column, index) => (
                <th key={index} scope="col" className={cn(column.className, "py-1 bg-white")} aria-sort={getAriaSortAttribute(column)}>
                    {!column.hideHeader && (
                        <div className="d-flex flex-row align-items-center">
                            <div className="my-auto">{column.name}</div>
                            <SortButton column={column} onSortChange={onSortChange} />
                        </div>
                    )}
                </th>
            ))}
            {hasActionsColumn && (
                <th scope="col" className={cn("py-1 bg-white", { "sticky-column-right": stickyActionsColumn })}>
                    Actions
                </th>
            )}
        </tr>
    );
};

interface DataGridHeaderProps {
    columns: DataGridColumnConfig[];
    hasActionsColumn?: boolean;
    onSortChange: (key: string) => void;
    stickyActionsColumn?: boolean;
    canSelectRows?: boolean;
}

const SortButton = ({ column, onSortChange }: { column: DataGridColumnConfig; onSortChange: (key: string) => void }) => {
    const columnName = (column.name ?? "").split(" ").map(capitalize).join(" ");
    const sortTitle = isEmpty(column.sort)
        ? `Sort By ${columnName} Ascending`
        : column.sort === "DESC"
        ? `Reset Sorting By ${columnName}`
        : `Sort By ${columnName} Descending`;

    return (
        <Button
            className="data-grid-column-sort px-2 py-1"
            variant="link"
            onClick={() => onSortChange(column.key)}
            title={sortTitle}
            aria-label={sortTitle}
        >
            <span className="fa-layers">
                {isEmpty(column.sort) ? (
                    <FontAwesomeIcon icon={"sort"} />
                ) : (
                    <>
                        <FontAwesomeIcon className={cn({ active: column.sort === "DESC" })} icon={"sort-up"} />
                        <FontAwesomeIcon className={cn({ active: column.sort !== "DESC" })} icon={"sort-down"} />
                    </>
                )}
            </span>
        </Button>
    );
};
