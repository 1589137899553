import { MutableRefObject } from "react";
import Form from "@rjsf/core";
import { ApplicationContactsWidgetRef, FormState } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { ContactData } from "components/utils/contacts";
import { flattenFormData, getApplicationFormSubmitValues } from "components/JsonForm/utils";
import { ApplicationItem } from "types/ApplicationItem";
import { getUrl, httpPostAuthorized, httpPutAuthorized } from "components/utils/http";
import { ApplicationFormWizardData } from "../../../types/ApplicationFormWizardData";
import { ApplicationFormWizardResponse } from "../../../types/ApplicationFormWizardResponse";
import { ApplicationCreateResponse } from "types/ApplicationFormCreateResponse";
import { isEmpty } from "lodash";
import Color from "color";
import { PortalConfiguration } from "types/PortalConfiguration";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { getCssColor } from "components/utils/color";
import { FormConfiguration } from "types/FormConfiguration";

export const getApplicationFormData = (
    applicationFormRef: MutableRefObject<Form<any> | undefined>,
    contactsWidgetRef: MutableRefObject<ApplicationContactsWidgetRef | undefined> | undefined,
    additionalContactsWidgetRef: MutableRefObject<ApplicationContactsWidgetRef | undefined> | undefined,
    applicationFormConfig: FormConfiguration,
    applicationInitialFormData: any
): ApplicationFormWizardData => {
    let applicationElements;
    let applicationItems: ApplicationItem[] = [];
    let applicationContacts: ContactData[] = [];

    if (!applicationFormRef.current) {
        return { applicationItems, applicationContacts };
    }

    const { schema, uiSchema } = applicationFormConfig;
    const { formData } = applicationFormRef.current.state as FormState;

    let applicationForm = flattenFormData(
        {
            schema,
            uiSchema,
            formData,
        },
        "af:fieldNumber"
    );

    // Get project name from application name widget.
    const applicationNameFieldNumber = applicationFormRef.current.props.formContext?.applicationNameRef.current?.fieldNumber;
    if (applicationNameFieldNumber && !isEmpty(applicationForm[applicationNameFieldNumber])) {
        applicationElements = {
            projectName: applicationForm[applicationNameFieldNumber],
        };
    }

    applicationItems = getApplicationFormSubmitValues(applicationForm, applicationInitialFormData);

    if (contactsWidgetRef?.current) {
        const widget = contactsWidgetRef.current;
        const contacts = widget.getSubmitValues();
        applicationContacts = applicationContacts.concat(contacts);
        applicationItems = applicationItems.filter((i) => i.fieldNumber !== widget.fieldNumber);
    }

    if (additionalContactsWidgetRef?.current) {
        const widget = additionalContactsWidgetRef.current;
        const contacts = widget.getSubmitValues();
        applicationContacts = applicationContacts.concat(contacts);
        applicationItems = applicationItems.filter((i) => i.fieldNumber !== widget.fieldNumber);
    }

    return { applicationElements, applicationItems, applicationContacts };
};

export const getStepperColors = (config: PortalConfiguration) => {
    const { settings } = config;

    const labelTextColor = getCssColor("--bs-body-color");
    const activeStepBackground = getCssColor("--bs-primary");
    const disabledStepBackground = getCssColor("--bs-gray-200");
    const stepTextColor = Color(activeStepBackground).isLight() ? "#333" : "#fff";
    const textSize = settings.fontSize;

    return {
        labelTextColor,
        activeStepBackground,
        disabledStepBackground,
        stepTextColor,
        completedStepBackground: activeStepBackground,
        textSize,
    };
};

export const validateContactsWidget = async (widgetRef: MutableRefObject<ApplicationContactsWidgetRef | undefined>, formName: string) => {
    return new Promise<void>((resolve, reject) => {
        if (widgetRef.current) {
            const errors = widgetRef.current.validate();

            if (errors) {
                sendAnalyticsEvent(AnalyticsEventType.FORM_ERROR, { formName });
                reject();
                return;
            }
        }

        resolve();
    });
};

export const validateContactsWidgetDataFormat = async (
    widgetRef: MutableRefObject<ApplicationContactsWidgetRef | undefined>,
    formName: string
) => {
    return new Promise<void>((resolve, reject) => {
        if (widgetRef.current) {
            const errors = widgetRef.current.validateDataFormat();

            if (errors) {
                sendAnalyticsEvent(AnalyticsEventType.FORM_ERROR, { formName });
                reject();
                return;
            }
        }

        resolve();
    });
};

export async function createApplication(programNumber: string, pageNumber: string): Promise<ApplicationCreateResponse> {
    return httpPostAuthorized(process.env.REACT_APP_APPLICATION_START_ENDPOINT ?? "", { programNumber, pageNumber });
}

export async function submitApplicationWizard(
    applicationNumber: string,
    pageNumber: string,
    isDraft: boolean,
    applicationData: ApplicationFormWizardData
): Promise<ApplicationFormWizardResponse> {
    const url =
        getUrl(process.env.REACT_APP_APPLICATION_WIZARD_ENDPOINT, { applicationNumber }) + `?pageNumber=${pageNumber}&isDraft=${isDraft}`;
    return httpPutAuthorized(url, applicationData);
}
