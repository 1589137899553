import { useEffect, useState } from "react";
import cn from "classnames";
import { WorkflowTask } from "./WorkflowTask";
import { refreshApplicationWorkflow, useApplicationWorkflow } from "components/WorkflowTasks/useApplicationWorkflow";
import { WorkflowFilterStepType, WorkFlowTaskModel } from "components/WorkflowTasks/types";
import { Task } from "./Task";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { formatJsonDateTime } from "components/utils/date";
import "./style.scss";
import { DataGrid } from "components/DataGrid";
import { isTaskCompleted } from "./utils";
import { orderBy } from "lodash";
import { AppTaskCompletedBadge, AppTaskToCompleteBadge } from "components/Badge";

export const WorkflowTasks = ({
    className,
    applicationNumber,
    stepType,
    isTable,
    onAfterTaskComplete,
    onChange,
    onWorkflowLoaded,
}: WorkflowTasksProps) => {
    const [workflow = [], isLoadingWorkflow] = useApplicationWorkflow(applicationNumber, stepType);
    const [activeTask, setActiveTask] = useState<WorkFlowTaskModel>();

    useEffect(() => {
        if (!isLoadingWorkflow) {
            onWorkflowLoaded?.();
        }
    }, [isLoadingWorkflow, onWorkflowLoaded]);

    useEffect(() => {
        onChange?.(workflow);
    }, [onChange, workflow]);

    const onTaskClick = (task: WorkFlowTaskModel) => {
        setActiveTask(task);
    };

    const onTaskComplete = async (task: WorkFlowTaskModel) => {
        sendAnalyticsEvent(AnalyticsEventType.WORKFLOW_TASK_COMPLETED, { type: task.stepType });

        await refreshApplicationWorkflow(applicationNumber, stepType);
        onAfterTaskComplete && onAfterTaskComplete();
        setActiveTask(undefined);
    };

    if (isLoadingWorkflow || !applicationNumber) {
        return null;
    }

    if (workflow.length === 0) {
        return <div className="mb-3 mt-3"> There are no tasks to complete. </div>;
    }

    return (
        <>
            {!isTable && (
                <>
                    <div className="mb-3 mt-3">The following items need action by you. Click each item to complete.</div>
                    <div className={cn("workflow-tasks", className)}>
                        {workflow.map((task, index) => (
                            <WorkflowTask key={index} task={task} onClick={onTaskClick} />
                        ))}
                    </div>
                </>
            )}
            {isTable && (
                <DataGrid className="submitted-application-tasks">
                    <tbody>
                        {orderBy(workflow, isTaskCompleted).map((task, index) => (
                            <tr key={index}>
                                <td data-label="Item" className="hide-mobile-label w-100">
                                    {task.step}
                                </td>
                                <td
                                    data-label="Date"
                                    className={cn("hide-mobile-label text-lg-end text-nowrap", {
                                        "p-0": !task.dateCompleted,
                                    })}
                                >
                                    {task.dateCompleted && formatJsonDateTime(task.dateCompleted)}
                                </td>
                                <td className="hide-mobile-label order-first order-lg-0 text-lg-end ">
                                    {isTaskCompleted(task) ? (
                                        <AppTaskCompletedBadge />
                                    ) : (
                                        <AppTaskToCompleteBadge onClick={() => onTaskClick(task)} />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </DataGrid>
            )}
            {activeTask && (
                <Task
                    applicationNumber={applicationNumber}
                    task={activeTask}
                    onComplete={onTaskComplete}
                    onClose={() => setActiveTask(undefined)}
                />
            )}
        </>
    );
};

interface WorkflowTasksProps {
    className?: string;
    applicationNumber?: string;
    stepType?: WorkflowFilterStepType;
    onAfterTaskComplete?: () => void;
    onChange?: (workflow: WorkFlowTaskModel[]) => void;
    onWorkflowLoaded?: () => void;
    isTable?: boolean;
}
